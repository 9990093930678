$gauge-meter-size: 218 !default;

.meter-component.gauge-meter {
  width: $gauge-meter-size + px;
  height: $gauge-meter-size + px;

  .bronze,
  .silver,
  .gold {
    stroke-width: 24px;
    stroke-linecap: butt;
    fill: none;
  }

  .bronze {
    transform: rotate(-45deg);
    stroke: #c29049;
  }
  .silver {
    transform: rotate(45deg);
    stroke: #a6bdc0;
  }
  .gold {
    transform: rotate(135deg);
    stroke: #e3be2a;
  }
  .rotatingGuage {
    transform: rotate(225deg);
  }

  .needle-bg {
    fill: #2c2c2c;
  }
  .needle-fg {
    fill: #525252;
  }
  .needle {
    stroke: #d2251d;
    stroke-width: 7px;
    stroke-linecap: round;
  }

  .background {
    fill: #525252;
  }
}
