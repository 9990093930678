$item-transition-duration: 300ms !default;
$item-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.ragged-layout {
  .item-container {
    .item-animate-enter {
      opacity: 0;
      transition: $item-transition-duration $item-transition-easing all;
    }

    .item-animate-enter.item-animate-enter-active {
      opacity: 1;
    }

    .item-animate-leave {
      opacity: 1;
      transition: $item-transition-duration $item-transition-easing all;
    }

    .item-animate-leave.item-animate-leave-active {
      opacity: 0;
    }

    .item-animate-move {
      transition: $item-transition-duration $item-transition-easing all;
    }

    .item-animate-move.item-animate-move-active {
    }
  }
}
