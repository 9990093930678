.site-content > .content-wrapper .content {
  &.profile,
  &.register {
    padding: 36px 65px;
    max-width: 1280px;
    // WARNING these styles override _register.scss
    // really should just have an boolean prop on AccountDisplay...
    // but this is easy enough
    @media screen and (max-width: 1023px) {
      padding: 36px;
    }
    
    .account-display-hide-points:hover {
      background-color: #071D49;
    }
    .account-display-hide-points {
      cursor: pointer; // TODO this should really be somewhere in image-input
      background-color: #6e6e6e;
      width: 120px;
      height: 120px;
      margin: auto;
      text-align: center;
      border-radius: 50%;

      .balance {
        display: none;
      }

      .points-display .avatar {
        float: unset;
        margin: auto;
        text-align: center;
        height: 100%;
        width: 100%;
        border-radius: 50%;

        .initials {
          font-size: 2.125rem;
          font-weight: 200;
          background-color: #00A9E0;
          color: #ffffff;
        }
      }
    }

    .wizard-container {
      padding: 65px;
      padding-top: 20px;
      padding-left: 25px;

      @media screen and (max-width: 1023px) {
        padding: 36px;
        padding-top: 16px;
      }

      @media screen and (min-width: 1440px) {
        padding-left: 0;
      }

      .step-list {
        display: none;
      }

      .wizard-content {
        padding-top: 0px;
        .image-input > label.file {
          margin-top: 7px;
        }

        .wizard-pane {
          & > div > div {
            //display: flex;
            //flex-direction: row;

            // form {
            //   .username {
            //     display: none;
            //     input:read-only {
            //       cursor: not-allowed;
            //     }
            //   }
            // }

            @media screen and (max-width: 1023px) {
              // why this was ever set to column-reverse is beyond me
              // it makes working with html in editor and inspector confusing
              flex-direction: column;
            }
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      form {
        display: block;
        margin: 0px;
        width: 100%;
        max-width: unset;
      }

      div[class*='form-group application-models-user-user[termsofuse]'] {
        width: 100%;
      }
      form > div.form-group:first-child > label > span > span {
        display: inline-block;
        height: 29px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      div.wizard-content > div.wizard-pane > div.active > div > form {
        margin: 0px auto;
        text-align: center;
      }
      // stop centering form labels
      div.wizard-content > div.wizard-pane > div.active > div > form > * {
        text-align: left;
      }
    }

    form:last-child {
      height: 100%;
      // max-width: 256px;
      // padding-left: 65px;
      // margin: 0 auto;
      width: 100%;
      max-width: 700px;

      @media screen and (max-width: 1023px) {
        padding-left: 0px;
        padding-bottom: 22px;
        position: relative;
      }
    }

    .form-group {
      // all these may not be necessary, but how many browsers do you want to test to find out?
      &.application-models-user-profile\[postal-code\],
      &.application-models-user-profile\[postal_code\],
      &.application-models-user-profile\[postalcode\],
      &.application-models-user-profile\[zip-code\],
      &.application-models-user-profile\[zip_code\],
      &.application-models-user-profile\[zipcode\],
      &.application-models-user-profile\[zip\],
      &.postal-code,
      &.postal_code,
      &.postalcode,
      &.zip-code,
      &.zip_code,
      &.zipcode,
      &.zip {
        display: inline-block;
        width: calc(50% - 5px);
        margin-left: 5px;
        vertical-align: top;
      }
      &.application-models-user-profile\[state\],
      &.state {
        display: inline-block;
        width: calc(50% - 5px);
        margin-right: 5px;

        select {
          width: 100%;
          min-width: auto;
        }
      }

      // &.username,
      // &.password,
      // &.confirmpassword,
      // &.securityquestion0,
      // &.securityanswer0,
      // &.securityquestion1,
      // &.securityanswer1 {
      //     display: none;
      // }

      /*
            &.submit {
                margin: 10px auto;
                margin-bottom: 0px;
                margin-top: 28px;
                padding: 0px 0px 0px 0px;
                border-top: solid 1px #bbbbbb;
                display: flex;
                flex-direction: row;

                @media screen and (max-width: 767px) {
                    width: 100%;
                    flex-direction: column;
                }
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                    .secondary {
                        margin-top: 41px;
                        margin-left: 30px !important;
                    }
                }

                button,
                .button {
                    width: calc(100% - 6px);
                    margin-left: auto;
                    margin-right: auto;
                    @media screen and (min-width: 768px) {
                        width: auto;
                        margin-left: 4px;
                        margin-right: 0px;
                    }
                    // margin: 28px 0px 0px 0px;

                    // &:first-child {
                    //     margin-right: 10px;

                    //     @media screen and (max-width: 1023px) {
                    //         margin-right: 0px;
                    //     }
                    // }
                }
                .secondary {
                    width: auto;
                }
            } */
      &.avatar {
        width: 100%;
        margin-top: 2px;
        .account-display-hide-points {
          margin: 0 auto;
        }
      }
    }
  }
}

.content {
  &.register {
    .wizard-container {
      @media screen and (max-width: 1023px) {
        padding-top: 0px;
      }
    }
  }
}

.content {
  &.profile {
    // .form-group.submit {
    //     border-top: none;
    // }
    @media screen and (min-width: 1024px) {
      .account-display-hide-points {
        --img-input-container-size: 120px;
        width: var(--img-input-container-size);
        height: var(--img-input-container-size);
        margin-left: 8px;
        margin-top: 12px;
      }
    }
    @media screen and (min-width: 1024px) {
      // form {
      //     justify-content: unset;
      // }
      form:first-child {
        //width: 28%;
        margin-bottom: 29px !important;
        // margin-left: 82px;
        margin-right: 22px;
      }
      form:nth-child(2) {
        //width: 68%;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .profile form:first-child {
    width: 100% !important;
    max-width: 700px;
  }
  form:first-child > div[class*='form-group application-models-user-profile[avatarimage]']:first-child,
  form:first-child > div.form-group.avatar:first-child {
    width: 100%;
    margin: 2px auto;
  }
}

.profile {
  .content-header {
    padding:0 25px;
    margin: 0 10px;
  }
  .content-description {
    padding:25px;
    margin: 0 10px;
  }
  .form-group {
    &.termsofuse {
      display: none;
    }
  }
}
