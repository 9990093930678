$alert-color-success: #3c763d;
$alert-color-info: #31708f;
$alert-color-warning: #8a6d3b;
$alert-color-error: #a94542;
$alert-background-color-success: #dff0d8;
$alert-background-color-info: #d9edf7;
$alert-background-color-warning: #fcf8e3;
$alert-background-color-error: #f2dede;
$alert-font-family: inherit;
$alert-font-size: 15px;
$alert-font-weight: normal;
$alert-line-height: inherit;
$alert-padding: 20px;
$alert-margin: 0px 0px 16px 0px;
$alert-box-shadow: none;

$alert-close-font-family: $alert-font-family;
$alert-close-font-size: 20px;
$alert-close-font-weight: bold;
$alert-close-line-height: $alert-line-height;
$alert-close-padding: 0px 5px;

$alert-transition-duration: 300ms;
$alert-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

@import '../../../app/q360-comps/alert/src/alert.scss';
