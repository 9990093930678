$branded-font-stack: 'Roboto', sans-serif !default;

$label-color: black !default;
$label-font-family: $branded-font-stack !default;
$label-font-size: 18px !default;
$label-font-weight: normal !default;
$label-padding: 0px !default;
$label-margin: 0px 0px 0px 0px !default;

/* */

$label-required-color: $label-color !default;

$label-required-asterisk-content: '*' !default;
$label-required-asterisk-color: $label-required-color !default;
$label-required-asterisk-font-family: $label-font-family !default;
$label-required-asterisk-font-size: $label-font-size !default;
$label-required-asterisk-font-weight: $label-font-weight !default;
$label-required-asterisk-padding: $label-padding !default;
$label-required-asterisk-margin: $label-margin !default;

$label-help-color: white !default;
$label-help-background-color: blue !default;
$label-help-padding: 0px !default;
$label-help-margin: 0px 0px 0px 5px !default;
$label-help-width: 13px !default;
$label-help-height: 13px !default;

$label-help-tooltip-color: white !default;
$label-help-tooltip-background-color: blue !default;
$label-help-tooltip-font-family: $branded-font-stack !default;
$label-help-tooltip-font-size: 15px !default;
$label-help-tooltip-font-weight: normal !default;
$label-help-tooltip-padding: 8px 21px 8px 21px !default;
$label-help-tooltip-margin: 0px 0px 0px 0px !default;
$label-help-tooltip-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2) !default;

$label-description-color: $label-color !default;
$label-description-font-family: $label-font-family !default;
$label-description-font-size: 15px !default;
$label-description-font-weight: normal !default;
$label-description-margin: 5px 0px 0px 0px !default;
$label-description-padding: $label-padding !default;

$label-error-color: white !default;
$label-error-background-color: firebrick !default;
$label-error-font-family: $label-font-family !default;
$label-error-font-size: 15px !default;
$label-error-font-weight: normal !default;
$label-error-margin: 5px 0px 0px 0px !default;
$label-error-padding: 10px !default;

$form-error-color: white !default;
$form-error-background-color: firebrick !default;
$form-error-font-family: $label-font-family !default;
$form-error-font-size: 15px !default;
$form-error-font-weight: normal !default;
$form-error-margin: 0px 0px 0px 0px !default;
$form-error-padding: 10px !default;

.form-error {
  p.error {
    box-sizing: border-box;
    display: block;
    position: relative;
    color: $form-error-color;
    background-color: $form-error-background-color;
    font-family: $form-error-font-family;
    font-size: $label-error-font-size;
    font-weight: $form-error-font-weight;
    margin: $form-error-margin;
    padding: $form-error-padding;
  }
}

.form-group {
  span.required {
    box-sizing: border-box;
    color: $label-required-color;

    &::after {
      content: $label-required-asterisk-content;
      color: $label-required-asterisk-color;
      font-family: $label-required-asterisk-font-family;
      font-size: $label-required-asterisk-font-size;
      font-weight: $label-required-asterisk-font-weight;
      padding: $label-required-asterisk-padding;
      margin: $label-required-asterisk-margin;
    }
  }

  a.help {
    box-sizing: border-box;
    margin: $label-help-margin;

    & > svg {
      width: $label-help-width;
      height: $label-help-height;

      .background {
        fill: $label-help-background-color;
      }

      .text {
        fill: $label-help-color;
      }
    }
  }

  div.help-tooltip {
    box-sizing: border-box;
    color: $label-help-tooltip-color !important;
    background-color: $label-help-tooltip-background-color !important;
    font-family: $label-help-tooltip-font-family !important;
    font-size: $label-help-tooltip-font-size !important;
    font-weight: $label-help-tooltip-font-weight !important;
    pointer-events: auto !important;
    box-shadow: $label-help-tooltip-box-shadow;
    padding: $label-help-tooltip-padding !important;
    max-width: 300px;

    &.place-top {
      &:after {
        border-top: $label-help-tooltip-background-color !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
      }
    }
  }

  span.description {
    box-sizing: border-box;
    display: block;
    color: $label-description-color;
    font-family: $label-description-font-family;
    font-size: $label-description-font-size;
    font-weight: $label-description-font-weight;
    margin: $label-description-margin;
    padding: $label-description-padding;
  }

  p.error {
    box-sizing: border-box;
    display: block;
    position: relative;
    color: $label-error-color;
    background-color: $label-error-background-color;
    font-family: $label-error-font-family;
    font-size: $label-error-font-size;
    font-weight: $label-error-font-weight;
    margin: $label-error-margin;
    padding: $label-error-padding;

    &:before {
      position: absolute;
      top: -8px;
      left: 20px;
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
}
