.tile {
  &.large {
    .task {
      &.calltoaction {
        .call-to-action-task-btn-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 1024px) {
            flex-direction: row;
          }
        }
      }
    }
  }
}
