//*******************************************************************************************/
//*********** global-defaults/forms.scss - the starting file for global-defaults ************/
//*******************************************************************************************/

.form-group {
  &.submit {
    margin: 1.5rem auto;
    padding-top: 1.5rem;
    border-top: solid 1px $light-grey;
  }
}
