.main-nav {
  .icon {
    text-align: left;
  }

  .subnav {
    position: fixed;
    top: 64px;
    height: calc(100vh - 157px);
    background-color: $white;
    border-top: 1.5rem solid $brand-primary-color;
    right: inherit;
    left: 0;
    padding: 0px;
    margin: 0px;
    box-shadow: 15px 0px 25px -15px $med-dark-grey;

    > div {
      // the first div inside the subnav also needs the background color set
      background-color: $white;
      height: calc(100vh - 157px); // header + dock => 64px + 69px = 133px

      @media screen and (min-width: 375px) and (max-width: 768px) {
        height: calc(100vh - 157px); // header + dock => 80px + 69px = 133px
        overflow-y: auto;
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        height: calc(100vh - 173px); // header + dock => 80px + 69px = 133px
        overflow-y: auto;
      }
      @media screen and (min-width: 1024px) {
        height: calc(100vh - 106px);
      }
    }

    @media screen and (min-width: 768px) {
      // this is when the top menu changes
      top: 80px;
      height: calc(100vh - 149px);
    }

    @media screen and (min-width: 1024px) {
      // switch to desktop layout
      left: inherit;
      right: 0px;
      height: calc(100vh - 82px);
      box-shadow: -15px 0px 25px -15px $med-dark-grey;
      overflow-x: hidden;
    }

    .subnav-user-info {
      background-color: $brand-primary-color;
      height: 11rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .points-display {
        border: none;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        order: 1;

        .balance {
          text-align: left;

          a {
            text-align: left;
            margin: 0;
            padding: 0;
            font-size: 13px;
            &:hover {
              background-color: initial;
            }
          }
          & * {
            color: $white !important; // all text in avatar container
          }
          &:before {
            display: none; // star with circle icone
          }
          .profile-user-name {
            display: block;
            margin-bottom: 0.5rem;
            span {
              display: block;
              line-height: 1.25;
            }
          }

          .val {
            font-size: 21px;
            &:after {
              content: ' ';
              width: 2px;
              display: inline-block;
            }
          }
        }
      }

      .avatar {
        width: 100px;
        height: 100px;
        border: 5px solid $white;
        border-radius: 55px;
        box-sizing: border-box;
        a {
          height: 100%;
          width: 100%;
          &:hover {
            background: none;
          }
          &:link {
            padding: 0;
          }
        }
      }

      .balance {
        a {
          min-width: auto;
        }
      }
    }
    a.nav-list-item-link {
      color: $near-black;
      &:hover {
        color: $brand-primary-color;
      }
    }

    ul {
      box-shadow: none;
      width: 295px;
      background-color: #ffffff;
    }
    * + * {
      border-top: 0;
    }

    a,
    a:link {
      font-size: 16px;
      padding: 1rem;
      height: auto;
      width: 100%;
      &:hover {
        .icon {
          color: #000000;
        }
      }
      &:hover {
        background-color: #ededed;
      }
    }
  }

  // My changes
  .subnav-reward{
    position: absolute;
    top: 44px;
    background: $reward-layout-bgcolor 0% 0% no-repeat padding-box;
    box-shadow: -15px 6px 25px -15px $reward-box-shadow;
    border-radius: 0px 0px 8px 8px;
    height: 418px;
    width: 375px;

    @media screen and (max-width: 375px) {
      left: -225px;
      top: -433px;
      border-radius: 10px 10px 0px 0px;
      box-shadow: none;
    }

    @media screen and (min-width: 376px) and (max-width: 768px) {
      left: -226px;
      top: -433px;
      border-radius: 10px 10px 0px 0px;
      box-shadow: none;
    }

    @media screen and (min-width: 768px) {
      // this is when the top menu changes
      top: 64px;
      height: 418px;
    }

    @media screen and (min-width: 1024px) {
      // switch to desktop layout
      left: inherit;
      right: 0px;
      height: 418px;
      box-shadow: -15px 6px 25px -15px $reward-box-shadow;
      overflow-x: hidden;
    }
  }

  .root-rewards {
    width: 375px;
    position: absolute;
  }

  .points-container {
    top: 90px;
    width: 302px;
    height: auto;
    opacity: 1;
    margin: 10px 39px 0px 33px;

    .points-section {
      margin-top: 20px;
      text-align: left;
      height: 65px;

      .icon1 {
        width: 22px;
        height: 22px;
        margin-right: 9px;
        float: left;
        background-image: url('../../../assets/img/Coins_Icn.svg');
        opacity: 1;
        top: 80px;
        margin-top: 3px;
      }

      .points-title-section {
        width: 40%;
        float: left;
        position: absolute;

        .points-title {
          font: normal normal bold 16px/21px adelle-sans;
          letter-spacing: 0px;
          color: $brand-primary-color;
          height: 21px;
          margin-left: 9px;
        }

        .points-date {
          height: 14px;
          font: normal normal normal 11px/14px adelle-sans;
          letter-spacing: 0px;
          color: $header-font-color;
          margin-bottom: 21.5px;
        }
      }

      .points-balance {
        height: 65px;
        text-align: right;
        font: normal normal bold 50px/65px adelle-sans;
        letter-spacing: 0px;
        color: $brand-primary-color;
        width: 43%;
        float: right;
        margin-top: -11px;
      }
    }

    .points-desc-section {
      margin: 20px 0px 0px 0px;
      text-align: left;
      height: 66px;

      .icon2 {
        width: 22px;
        height: 22px;
        background-image: url('../../../assets/img/Marketplace_Icon.svg');
        opacity: 1;
        top: 80px;
        margin-right: 9px;
        float: left;
        margin-top: 3px;
      }

      .points-desc-wrap {
        width: 100%;
        height: 59px;
        text-align: left;
        font: normal normal normal 16px/20px adelle-sans;
        letter-spacing: 0px;
        color: #00A9E0;
        opacity: 1;
      }
    }
  }

  .rewards-menus {
    margin: 0px 42px 42px 37px;

    > ul {
      padding: 0px;

      > li {
        width: 100%;
        height: 96px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid $brand-primary-color;
        border-radius: 4px;
        list-style-type: none;
        margin-bottom: 13px;

        .nav-list-item-link {
          width: auto;
          height: auto;
          justify-content: normal;
          align-items: normal;

          .Merchandise-title {
            margin: -60px 51px 37px 151px;
            font: normal normal normal 16px/21px adelle-sans;
            letter-spacing: 0px;
            color: #00A9E0;
            width: 94px;
            height: 21px;
          }

          .Experiences-title {
            font: normal normal normal 16px/21px adelle-sans;
            letter-spacing: 0px;
            color: #00A9E0;
            margin: -59px 51px 37px 151px;
          }

          .Merchandise-arrow {
            background: url('../../../assets/img/external-link-icon.svg');
            width: 11.55px;
            height: 11.48px;
            margin: -53px 21.5px 42.5px 263px;

            @media screen and (max-width: 480px) {
              width: 10.55px;
              height: 12px;
            }
          }

          .Experiences-arrow {
            background: url('../../../assets/img/external-link-icon.svg');
            width: 11.55px;
            height: 12.48px;
            margin: -53px 21.5px 42.5px 263px;

            @media screen and (max-width: 480px) {
              width: 10.55px;
              height: 12px;
            }
          }
        }

        .Merchandise-img {
          background: url('../../../assets/img/redeem-MM.png');
          width: 129px;
          height: 76px;
          cursor: pointer;
          margin: 8px 0px 10px 6px;
        }

        .Experiences-img {
          background: url('../../../assets/img/redeem-EM2.png');
          width: 129px;
          height: 76px;
          cursor: pointer;
          margin: 8px 0px 10px 6px;
        }
      }
    }
  }
  ////
}
.subnav-overlay {
  //background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  right: 0;
  top: 64px;
  height: calc(100vh - 133px); // 64px header and 69px dock for mobile
  z-index: -1;
  @media screen and (min-width: 768px) {
    // tablet
    top: 80px;
    height: calc(100vh - 144px); // 80px header and 69px dock
  }
  @media screen and (min-width: 1024px) {
    // when on desktop move overlay to the left
    left: 0;
    height: calc(100vh - 80px); // 80px header
    position: absolute;
  }
}
