$label-color: black !default;
$label-font-family: inherit !default;
$label-font-weight: normal !default;
$label-font-size: 24px !default;
$label-line-height: inherit !default;
$label-margin: 0px !default;

$input-font-family: inherit !default;
$input-font-weight: normal !default;
$input-font-size: 20px !default;
$input-line-height: inherit !default;
$input-margin: 5px 0px 0px 0px !default;

/**********************   */

$quiz-question-margin: 0px 0px 20px 0px !default;

$quiz-question-label-color: $label-color !default;
$quiz-question-label-font-family: $label-font-family !default;
$quiz-question-label-font-weight: $label-font-weight !default;
$quiz-question-label-font-size: $label-font-size !default;
$quiz-question-label-line-height: $label-line-height !default;
$quiz-question-label-margin: 0px 0px 5px 0px !default;

$quiz-answer-label-color: $label-color !default;
$quiz-answer-label-font-family: $label-font-family !default;
$quiz-answer-label-font-weight: $label-font-weight !default;
$quiz-answer-label-font-size: $label-font-size !default;
$quiz-answer-label-line-height: $label-line-height !default;
$quiz-answer-label-margin: 10px 0px 0px 0px !default;

$quiz-answer-input-font-family: $input-font-family !default;
$quiz-answer-input-font-weight: $input-font-weight !default;
$quiz-answer-input-font-size: $input-font-size !default;
$quiz-answer-input-line-height: $input-line-height !default;
$quiz-answer-input-margin: 0px 0px 0px 0px !default;

$quiz-question-message-color: black !default;
$quiz-question-message-font-family: inherit !default;
$quiz-question-message-font-weight: normal !default;
$quiz-question-message-font-size: 20px !default;
$quiz-question-message-line-height: inherit !default;
$quiz-question-message-margin: 10px 0px 0px 0px !default;

$quiz-question-correct-label-color: green !default;
$quiz-question-correct-message-color: green !default;

$quiz-question-incorrect-label-color: red !default;
$quiz-question-incorrect-message-color: red !default;

.task-container {
  .error-carousel.quiz {
    display: none;
  }
}

.quiz-question-container {
  text-align: left;

  p.message {
    color: $quiz-question-message-color;
    font-family: $quiz-question-message-font-family;
    font-weight: $quiz-question-message-font-weight;
    font-size: $quiz-question-message-font-size;
    line-height: $quiz-question-message-line-height;
    margin: $quiz-question-margin;

    &.correct {
      color: $quiz-question-correct-message-color;
    }

    &.incorrect {
      color: $quiz-question-incorrect-message-color;
    }
  }

  .question-container {
    margin: $quiz-question-margin;

    & > fieldset {
      border: none;
      padding: 0px;
      margin: 0px;
    }

    .question {
      display: block;
      color: $quiz-question-label-color;
      font-family: $quiz-question-label-font-family;
      font-weight: $quiz-question-label-font-weight;
      font-size: $quiz-question-label-font-size;
      line-height: $quiz-question-label-line-height;
      margin: $quiz-question-label-margin;
    }

    .form-group {
      margin: 0px;

      label,
      legend {
        color: $quiz-answer-label-color;
        font-family: $quiz-answer-label-font-family;
        font-weight: $quiz-answer-label-font-weight;
        font-size: $quiz-answer-label-font-size;
        line-height: $quiz-answer-label-line-height;
        margin: $quiz-answer-label-margin;
      }

      label.checkbox,
      label.radio {
        font-family: $quiz-answer-input-font-family;
        font-weight: $quiz-answer-input-font-weight;
        font-size: $quiz-answer-input-font-size;
        line-height: $quiz-answer-input-line-height;
        margin: $quiz-answer-input-margin;
      }

      select {
        margin: $quiz-answer-input-margin;
      }

      &.correct {
        label.checkbox,
        label.radio {
          color: $quiz-question-correct-label-color;
        }
      }

      &.incorrect {
        label.checkbox,
        label.radio {
          color: $quiz-question-incorrect-label-color;
        }
      }
    }

    .message {
      color: $quiz-question-message-color;
      font-family: $quiz-question-message-font-family;
      font-weight: $quiz-question-message-font-weight;
      font-size: $quiz-question-message-font-size;
      line-height: $quiz-question-message-line-height;
      margin: $quiz-question-message-margin;
    }

    div.answer {
      display: inline;
    }

    &.correct {
      .message {
        color: $quiz-question-correct-message-color;
      }
    }

    &.incorrect {
      .message {
        color: $quiz-question-incorrect-message-color;
      }
    }
  }
}
