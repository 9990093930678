@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
.clearfix {
  @include clearfix;
}

@mixin block {
  box-sizing: border-box;
  position: relative;
  width: $block-width;
  min-height: $block-height;
  color: $block-unlocked-color;
  background-color: $block-unlocked-background-color;
  border-color: $block-unlocked-border-color;
  border-style: $block-border-style;
  border-width: $block-border-width;
  border-radius: $block-border-radius;
  font-family: $block-font-family;
  font-size: $block-font-size;
  font-weight: $block-font-weight;
  line-height: $block-line-height;
  margin: $block-margin;
  padding: $block-padding;
  text-align: left;
}
