$footer-header-color: #ffffff;
$footer-header-font-family: inherit;
$footer-header-font-size: 25px;
$footer-header-font-weight: normal;
$footer-header-line-height: inherit;
$footer-header-padding: 0px 5px 0px 0px;
$footer-header-margin: 0px 0px 10px 0px;

$footer-link-color: #bfbfbf;
$footer-link-font-family: inherit;
$footer-link-font-size: 15px;
$footer-link-font-weight: normal;
$footer-link-line-height: inherit;
$footer-link-padding: 0px;
$footer-link-margin: 0px 0px 3px 0px;

@import '../../../app/q360-comps/footer-nav/src/footer-nav.scss';
@import '../../../app/q360-comps/social-media-nav/src/social-media-nav.scss';

footer {
  width: 100%;
  flex: none;
  background-color: $footer-background-color;
  color: $footer-font-color;
  overflow: hidden; //prevent inherit margin from children.
  margin-top: auto; // push to bottom
  .content {
    max-width: $content-wrapper-max-width;
  }
}

footer {
  @media screen and (max-width: 1023px) {
    margin-bottom: 69px;
    padding-bottom: 5em;
  }
  background-color: $footer-background-color;
  font-size: 11px;
  color: #fff;
  line-height: 1.636;
  @media screen and (min-width: 1024px) {
    z-index: 21; // The adjacent element in markup [div.page] has the same z-index
  }
}

.getAcrobatReader {
  padding: 0 5% 1rem 5%;
  a:hover {
    color: inherit;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    // align-items: center;
    a {
      margin-left: 0.5rem;
    }
  }
  @media screen and (min-width: 1440px) {
    padding: 0;
  }
}

.footer-nav {
  box-sizing: border-box;
  display: inline-block;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 14px;
  vertical-align: top;

  .nav-list-item-text h1,
  .nav-list-item-text h2 {
    font-size: 25px;
    font-weight: normal;
  }

  @media screen and (max-width: 1023px) {
    display: block;
    width: 90%;
    margin: 0 auto;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    margin-left: 5%;
  }
}

.social-media-nav {
  box-sizing: border-box;
  display: inline-block;
  width: 40%;
  padding-top: 10px;
  vertical-align: top;
  text-align: right;

  @media screen and (max-width: 1023px) {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }

  ul {
    margin-top: 16px;
    margin-bottom: 38px;

    @media screen and (max-width: 1023px) {
      margin-top: 0px;
    }
  }
}

.content-wrapper > .footer-nav {
  padding: 0;
}
nav.footer-nav > ul.nav-list > li.nav-list-item:not(:first-child) {
  padding-left: 17px;
  padding-bottom: 2px;
}

.copy {
  display: block;
  clear: both;
  padding-left: 5%;
  padding-bottom: 29px;
  font-size: 10px;
  font-weight: 100;

  @media screen and (min-width: 1440px) {
    padding-left: 0;
  }
}
