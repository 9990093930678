.content {
  &.login {
    max-width: 450px;

    .content-header {
      font-size: 42px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .content-description {
      margin-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .content-body {
      //background: none;
    }

    .form-group {
      .forgot-username,
      .forgot-password {
        padding: 5px 0px 0px 0px;
        text-align: right;

        a {
          color: black;
          font-weight: bold;
          margin: 5px 0px 0px 0px;
        }
      }

      &.submit {
        text-align: right;
        border-top: solid 1px #aeaeae;
        padding: 15px 0px;
      }
    }
  }
}
