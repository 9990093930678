.site-content > .content-wrapper .content {
  &.privacy-policy {
    max-width: 1280px;
    padding: 40px 65px;

    @media screen and (max-width: 1023px) {
      padding: 40px;
    }
  }
}
