//*******************************************************************************************/
//************** variables/base.scss - the starting file for global-defaults ****************/
//*******************************************************************************************/


$branded-font-stack: 'Roboto', sans-serif;
$global-default-font-color: #6e6e6e;
$global-original-dark-grey-font-color: #6e6e6e; // rgb(110, 110, 110)

// $site-content-vert-padding: 16px;
// setting to zero since some pages have videos at top which have no paddings/margin
$site-content-vert-padding: 0px;

// looks like form elements on profile page need to come in 14px on each side
// so adding 7px to this horz padding value, and 7px to another padding value..
// And the element that I'd need to add it to is wizard-container...
// 22px, was 20px, which aligns inputs with right side, but 22px aligns left side of inputs, which is preferable, was 7px/23px, due to measuring at wrong resolution
// this is all focusing on profile page on mobile
// $site-content-horz-padding: 16px + 6px;
// setting to zero since some pages have videos at top which have no paddings/margin
$site-content-horz-padding: 0px;

$language-bar-height: 30px;

$admin-bar-height: 50px;

$header-height: 80px;

// in photoshop, it appears that this should be 64px
// $header-height-mobile: 68px + 8px; // 76px
$header-height-mobile: 64px;

$dock-height-mobile: 69px;
