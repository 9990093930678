.video-btns {
  // @media screen and (min-width: 1024px) {
  //   display: flex;
  //   // margin-top: 12rem;
  // }

  .secondary.watch-video {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
}
