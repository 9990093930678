.content {
  &.not-found {
    @media screen and (min-width: 1024px) {
      display: flex;
      justify-content: center;
    }

    .content-message {
      text-align: center;
      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35%;
        order: -1;
        text-align: left;
      }

      .content-header {
        font-size: 21px;
        width: 90%;
        margin: 16px auto;
        line-height: 1.2;

        @media screen and (min-width: 768px) {
          font-size: 34px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 42px;
          width: 100%;
          margin: auto;
        }
      }

      .content-description {
        font-size: 16px;
        line-height: 1.5;

        @media screen and (min-width: 768px) {
          font-size: 21px;
          width: 55%;
          margin: auto;
        }

        @media screen and (min-width: 1024px) {
          width: 100%;
          margin: auto;
          font-size: 26px;
        }
      }
    }

    .content-image {
      display: flex;
      justify-content: center;
      @media screen and (min-width: 1024px) {
        width: 60%;
      }
      img,
      svg {
        width: 90%;

        @media screen and (min-width: 500px) {
          width: 70%;
        }

        @media screen and (min-width: 630px) {
          width: 60%;
        }

        @media screen and (min-width: 768px) {
          width: 50%;
        }
      }
    }
  }
}
