$panel-header-color: #ffffff;
$panel-header-background-color: #595959;
$panel-header-font-family: $branded-font-stack;
$panel-header-font-size: 20px;
$panel-header-font-weight: normal;
$panel-header-line-height: inherit;
$panel-header-padding: 15px 15px 15px 15px;

$panel-title-padding: 2px 15px 0px 15px;

$panel-badge-color: #595959;
$panel-badge-background-color: #ffffff;
$panel-badge-font-family: $branded-font-stack;
$panel-badge-font-size: 11px;
$panel-badge-font-weight: bold;
$panel-badge-line-height: inherit;
$panel-badge-padding: 5px;

$panel-body-color: #000000;
$panel-body-background-color: #f4f4f4;
$panel-body-font-family: $branded-font-stack;
$panel-body-font-size: 16px;
$panel-body-font-weight: normal;
$panel-body-line-height: inherit;
$panel-body-padding: 25px 25px 25px 25px;

$panel-new-color: $panel-body-color;
$panel-new-background-color: $panel-body-background-color;
$panel-new-font-family: $panel-body-font-family;
$panel-new-font-size: $panel-body-font-size;
$panel-new-font-weight: bold;
$panel-new-line-height: inherit;
$panel-new-padding: 0px;

@import '../../../app/q360-comps/expansion-panel/src/expansion-panel.scss';
// ./../../../app/scss/components/accordion

.accordion {
  .panel {
    margin-top: 1px;

    .panel-heading {
      background-color: $panel-header-background-color;
    }

    .panel-body {
      background-color: #fff;
    }
  }

  // For a long time this was ::first-child which means it may not have been getting used at all
  // regardless, in centene it appears to either not or imperceptibly change things visually.
  .panel:first-child {
    margin-top: 0px;
  }
}
