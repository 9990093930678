/* stylelint-disable */
// stylelint won't ignore this file... so font src: url(.. indentation isn't perfect

/* FAKE OPEN SANS */


@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Black.ttf') format('truetype');
    // src: url('../../fonts/Roboto-Black.woff') format('woff');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Regular.ttf') format('truetype');
    // src: url('../../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-BlackItalic.ttf') format('truetype');
    // src: url('../../fonts/Roboto-BlackItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


