$task-color: black;
$task-background-color: white;
$task-font-family: $branded-font-stack;
$task-font-size: 15px;
$task-font-weight: normal;
$task-line-height: inherit;
$task-margin: 20px;

$task-header-color: black;
$task-header-font-family: $task-font-family;
$task-header-font-size: 30px;
$task-header-font-weight: normal;
$task-header-line-height: inherit;
$task-header-margin: 0px;

$task-text-color: $task-color;
$task-text-font-family: $task-font-family;
$task-text-font-size: $task-font-size;
$task-text-font-weight: $task-font-weight;
$task-text-line-height: $task-line-height;
$task-text-margin: 10px 0px 10px 0px;

$task-expiration-color: #bfbfbf;
$task-expiration-font-family: $task-font-family;
$task-expiration-font-size: $task-font-size;
$task-expiration-font-weight: $task-font-weight;
$task-expiration-line-height: $task-line-height;
$task-expiration-margin: 2px 0px 0px 0px;

$task-complete-color: #858585 !default;
$task-complete-font-family: $task-font-family !default;
$task-complete-font-size: 18px !default;
$task-complete-font-weight: bold !default;
$task-complete-line-height: 1 !default;
$task-complete-margin: 0px 0px 0px 0px !default;

$task-complete-date-color: #858585 !default;
$task-complete-date-font-family: $task-font-family !default;
$task-complete-date-font-size: 9px !default;
$task-complete-date-font-weight: lighter !default;
$task-complete-date-line-height: 1 !default;
$task-complete-date-margin: 0px 0px 0px 0px !default;

$task-error-color: red;
$task-error-font-family: $task-font-family;
$task-error-font-size: $task-font-size;
$task-error-font-weight: $task-font-weight;
$task-error-line-height: $task-line-height;
$task-error-margin: 0px 0px 3px 0px;

$task-read-more-color: #bfbfbf;
$task-read-more-font-family: $task-font-family;
$task-read-more-font-size: 18px;
$task-read-more-font-weight: bold;
$task-read-more-line-height: inherit;
$task-read-more-margin: 15px 0px 10px 0px;

$task-link-button-color: #bfbfbf;
$task-link-button-font-family: $task-font-family;
$task-link-button-font-size: 18px;
$task-link-button-font-weight: bold;
$task-link-button-line-height: inherit;

$task-page-color: white;
$task-page-active-color: white;
$task-page-complete-color: white;
$task-page-complete-active-color: white;
$task-page-background-color: #b5b5b5;
$task-page-active-background-color: black;
$task-page-complete-background-color: #00b000;
$task-page-complete-active-background-color: #006000;
$task-page-border: 0px solid white !default;
$task-page-border-radius: 16px !default;
$task-page-active-border-color: white !default;
$task-page-complete-border-color: white !default;
$task-page-complete-active-border-color: white !default;

$task-page-height: 26px;
$task-page-font-family: $task-font-family;
$task-page-font-size: 15px;
$task-page-font-weight: normal;
$task-page-line-height: $task-page-height;
$task-page-margin: 0px 2.5px;

$task-slide-duration: 300ms;
$task-slide-easing: ease-out;

$task-error-slide-duration: 300ms;
$task-error-slide-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

$task-label-color: black;
$task-label-font-family: $branded-font-stack;
$task-label-font-size: 15px;
$task-label-font-weight: bold;
$task-label-line-height: inherit;
$task-label-padding: 0px;
$task-label-margin: 0px 0px 10px 0px;

$task-input-color: black;
$task-input-color-placeholder: #999;
$task-input-background-color: white;
$task-input-background-color-disabled: #e0e0e0;
$task-input-border-size: 1px;
$task-input-border-color: #c0c0c0;
$task-input-border-radius: 0px;
$task-input-font-family: $branded-font-stack;
$task-input-font-size: 20px;
$task-input-font-weight: normal;
$task-input-line-height: inherit;
$task-input-height: 36px;
$task-input-padding: 4px 8px;
$task-input-margin: 0px 0px 10px 0px;

@import '../../../app/q360-comps/task/src/task.scss';
@import '../../../app/q360-comps/call-to-action-task/src/call-to-action-task.scss';
@import '../../../app/q360-comps/code-input-task/src/code-input-task.scss';
@import '../../../app/q360-comps/data-capture-task/src/data-capture-task.scss';
@import '../../../app/q360-comps/file-task/src/file-task.scss';
@import '../../../app/q360-comps/message-task/src/message-task.scss';
@import '../../../app/q360-comps/opt-in-task/src/opt-in-task.scss';
@import '../../../app/q360-comps/video-task/src/video-task.scss';

.task {
  div.text + form > .form-group.submit:first-child {
    margin-top: 25px;
  }
}

$quiz-question-margin: 0px 0px 20px 0px;

$quiz-question-label-color: $label-color;
$quiz-question-label-font-family: $label-font-family;
$quiz-question-label-font-weight: $label-font-weight;
$quiz-question-label-font-size: 24px; //WIP# 32748;  old value: $label-font-size;
$quiz-question-label-line-height: $label-line-height;
$quiz-question-label-margin: 0px 0px 5px 0px;

$quiz-answer-label-color: $label-color;
$quiz-answer-label-font-family: $label-font-family;
$quiz-answer-label-font-weight: $label-font-weight;
$quiz-answer-label-font-size: $label-font-size;
$quiz-answer-label-line-height: $label-line-height;
$quiz-answer-label-margin: 10px 0px 0px 0px;

$quiz-answer-input-font-family: $input-font-family;
$quiz-answer-input-font-weight: $input-font-weight;
$quiz-answer-input-font-size: $input-font-size;
$quiz-answer-input-line-height: $input-line-height;
$quiz-answer-input-margin: 0px 0px 0px 0px;

$quiz-question-message-color: black;
$quiz-question-message-font-family: inherit;
$quiz-question-message-font-weight: normal;
$quiz-question-message-font-size: 16px;
$quiz-question-message-line-height: inherit;
$quiz-question-message-margin: 10px 0px 0px 0px;

$quiz-question-correct-label-color: green;
$quiz-question-correct-message-color: green;

$quiz-question-incorrect-label-color: red;
$quiz-question-incorrect-message-color: red;

@import '../../../app/q360-comps/quiz-task/src/quiz-task.scss';

$survey-question-margin: 0px 0px 20px 0px;

$survey-question-label-color: $label-color;
$survey-question-label-font-family: $label-font-family;
$survey-question-label-font-weight: $label-font-weight;
$survey-question-label-font-size: $label-font-size;
$survey-question-label-line-height: $label-line-height;
$survey-question-label-margin: 0px 0px 5px 0px;

$survey-answer-label-color: $label-color;
$survey-answer-label-font-family: $label-font-family;
$survey-answer-label-font-weight: $label-font-weight;
$survey-answer-label-font-size: $label-font-size;
$survey-answer-label-line-height: $label-line-height;
$survey-answer-label-margin: 10px 0px 0px 0px;

$survey-answer-input-font-family: $input-font-family;
$survey-answer-input-font-weight: $input-font-weight;
$survey-answer-input-font-size: $input-font-size;
$survey-answer-input-line-height: $input-line-height;
$survey-answer-input-margin: 0px 0px 0px 0px;

$survey-question-message-color: red !default;
$survey-question-message-font-family: inherit !default;
$survey-question-message-font-weight: normal !default;
$survey-question-message-font-size: 16px !default;
$survey-question-message-line-height: inherit !default;
$survey-question-message-margin: 10px 0px 0px 0px !default;

a.back,
.close,
button.close {
  background-color: transparent;
  border: none;
  margin-left: 0;
  display: flex;
  align-self: flex-end;
  min-width: auto;
  width: initial;
  padding-top: 1rem;
  margin-top: 0;
  &:hover {
    background-color: transparent;
    border: none;
  }
}

@import '../../../app/q360-comps/survey-task/src/survey-task.scss';
