$flag-color: #ffffff !default;
$flag-background-color: #6a6a6a !default;
$flag-shadow-color: #252525 !default;
$flag-height: 68px !default;
$flag-width: 68px !default;

$flag-header-font-family: inherit !default;
$flag-header-font-size: 21px !default;
$flag-header-font-weight: bold !default;
$flag-header-line-height: 1.4 !default;
$flag-header-margin: 0px !default;

$flag-text-font-family: inherit !default;
$flag-text-font-size: 11px !default;
$flag-text-font-weight: normal !default;
$flag-text-line-height: 1 !default;
$flag-text-margin: 0px !default;

$flag-appendage-width: 7px !default;

.flag {
  position: absolute;
  left: 0px;
  color: $flag-color;
  z-index: 1;

  .wrapper {
    position: relative;
    top: 12px;
    background-color: $flag-background-color;
    width: $flag-width;
    height: $flag-height;
  }

  .appendage {
    position: absolute;
    left: ($flag-appendage-width * -1);
    width: $flag-appendage-width;
    top: 0px;
    bottom: 0px;
    background-color: $flag-background-color;
    z-index: -1;

    svg.shadow {
      position: absolute;
      bottom: ($flag-appendage-width * -1);
      width: $flag-appendage-width;
      height: $flag-appendage-width;
      fill: $flag-shadow-color;
    }
  }

  .content {
    margin: 0px 0px 0px ($flag-appendage-width * -1);
    padding: $flag-appendage-width 0px $flag-appendage-width 0px;
    text-align: center;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-family: $flag-text-font-family;
      font-size: $flag-text-font-size;
      font-weight: $flag-text-font-weight;
      line-height: $flag-text-line-height;
      margin: $flag-text-margin;

      sup {
        font-family: $flag-text-font-family;
        font-size: $flag-text-font-size;
        font-weight: $flag-text-font-weight;
        line-height: $flag-text-line-height;
      }
    }
  }

  &.one-line {
    p:nth-child(1) {
      font-family: $flag-header-font-family;
      font-size: $flag-header-font-size;
      font-weight: $flag-header-font-weight;
      line-height: $flag-header-line-height;
      margin: $flag-header-margin;
    }
  }

  &.two-line-large-line-first {
    .content {
      padding: 0px 0px $flag-appendage-width 0px;
    }

    p:nth-child(1) {
      font-family: $flag-header-font-family;
      font-size: $flag-header-font-size;
      font-weight: $flag-header-font-weight;
      line-height: $flag-header-line-height;
      margin: $flag-header-margin;
    }
  }

  &.two-line-small-line-first {
    .content {
      padding: $flag-appendage-width 0px 0px 0px;
    }

    p:nth-child(2) {
      font-family: $flag-header-font-family;
      font-size: $flag-header-font-size;
      font-weight: $flag-header-font-weight;
      line-height: $flag-header-line-height;
      margin: $flag-header-margin;
    }
  }
}

@media screen and (min-width: 1024px) {
  .tile.reverse {
    &.large {
      .flag {
        left: auto;
        left: initial;
        right: 0px;

        .appendage {
          left: auto;
          left: initial;
          right: ($flag-appendage-width * -1);

          svg.shadow {
            transform: rotate(270deg);
          }
        }

        .content {
          margin: 0px ($flag-appendage-width * -1) 0px 0px;
        }
      }
    }
  }
}
