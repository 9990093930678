.challenge-detail {
  .challenge-detail-img {
    position: relative;
    height: 320px;

    .container {
      max-width: 1280px;
      margin: auto;
    }
    .challenge-detail-hero-img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }

    .total_points {
      position: absolute;
      top: 0;
      left: 6%;
      padding: 2px 8px;
      border-radius: 5px;
      background-color: #fff;
      color: $brand-primary-color;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;

      .points {
        fill: $brand-primary-color;
        width: 13px;
        height: 13px;
        margin-right: 4px;
      }

      .pts {
        font-size: 10px;
        margin-top: 2.5px;
        margin-left: 2px;
      }
    }

    .challenge-detail-img-text {
      padding: 0 1rem;
      position: absolute;
      bottom: 8%;
      width: 90%;

      @media screen and (min-width: 768px) {
        padding: 0;
        left: 6%;
      }

      .title {
        margin: 0;
        color: #fff;
        font-size: 16px;

        @media screen and (min-width: 768px) {
          font-size: 21px;
        }
        @media screen and (min-width: 1024px) {
          font-size: 42px;
          font-weight: 200;
        }
      }

      .status {
        margin: 0;
        color: #fff;
        .completed,
        .of,
        .total {
          font-size: 2.4rem;
          @media screen and (min-width: 768px) {
            font-size: 42px;
          }
          @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
          }
        }

        .completed {
          font-weight: 700;
        }

        .total {
          font-weight: 100;
        }

        .points {
          margin-left: 0.2rem;
          font-weight: 100;
        }
      }

      .react-sweet-progress {
        @media screen and (min-width: 630px) {
          max-width: 360px;
        }
      }

      .react-sweet-progress-line {
        background-color: #ccc;
      }

      .react-sweet-progress-line-inner-status-active:before {
        display: none;
      }

      .react-sweet-progress-symbol {
        display: none;
      }

      .bonus {
        font-size: 11px;
        color: #fff;
        span {
          padding-left: 16%;

          @media screen and (min-width: 630px) {
            padding-left: 60px;
          }
        }
      }

      button.primary {
        @media screen and (max-width: 767px) {
          width: 192px;
        }
      }
    }
  }

  .challenge-detail-content {
    padding: 1rem;

    .container {
      max-width: 1280px;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        padding: 0 2rem;
      }
      @media screen and (min-width: 1024px) {
        padding: 0 2.5rem;
      }
      @media screen and (min-width: 1440px) {
        padding: 0;
      }
    }
    h3 {
      color: #071D49;
      margin: 0;
      font-weight: 400;
      font-size: 21px;
      // @media screen and (min-width: 768px) {
      //     padding-top: 24px;
      // }
      .badge {
        position: relative;
        bottom: 2px;
        background: #6e6e6e;
        border-radius: 50%;
        color: #fff;
        width: 1rem;
        padding: 0.1rem 0.4rem;
        font-size: 10px;
        vertical-align: middle;
        font-weight: 100;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .challenge-list-item {
      text-decoration: none;
      display: flex;
      align-items: center;

      .success-checkmark,
      .caret-right {
        width: 10%;
      }

      .success-checkmark {
        width: 40px;
        height: 40px;

        @media screen and (min-width: 1024px) {
          width: 64px;
          height: 64px;
        }
      }

      .caret-right {
        height: 12px;
        path {
          fill: #6e6e6e;
        }
      }

      .detail-content {
        flex: 1;
        margin: 0.5rem 0 0.5rem 0.75rem;

        .date {
          text-transform: uppercase;
          font-size: 10px;
          color: #747676;
        }

        .taskTitle {
          color: #000;
        }
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #000;

        span {
          margin-right: 1rem;
          font-size: 13px;
        }
      }
    }

    li:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
}
