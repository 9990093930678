$account-tile-color: white !default;
$account-tile-background-color: #00A9E0 !default;
$account-tile-margin: 0px !default;
$account-tile-padding: 40px 0px !default;
$account-tile-box-shadow: none !default;

$account-tile-avatar-height: 125px !default;
$account-tile-avatar-width: 125px !default;
$account-tile-avatar-color: $header-font-color !default;
$account-tile-avatar-background-color: black !default;
$account-tile-avatar-border-radius: 62.5px !default;
$account-tile-avatar-font-family: inherit !default;
$account-tile-avatar-font-size: 60px !default;
$account-tile-avatar-font-weight: normal !default;
$account-tile-avatar-line-height: inherit !default;
$account-tile-avatar-margin: 0px 15px 0px 15px !default;

$account-tile-balance-color: $header-font-color !default;
$account-tile-balance-font-family: inherit !default;
$account-tile-balance-font-weight: normal !default;
$account-tile-balance-font-size: 32px !default;
$account-tile-balance-line-height: 1 !default;
$account-tile-balance-padding: 0px !default;
$account-tile-balance-margin: 0px 15px 0px 15px !default;
$account-tile-balance-text-align: center !default;

$account-tile-balance-value-color: $header-font-color !default;
$account-tile-balance-value-font-family: inherit !default;
$account-tile-balance-value-font-weight: normal !default;
$account-tile-balance-value-font-size: 60px !default;
$account-tile-balance-value-line-height: 1 !default;
$account-tile-balance-value-padding: 0px !default;
$account-tile-balance-value-margin: 0px !default;

.account-display-tile {
  display: none;
  color: $account-tile-color;
  background-color: $account-tile-background-color;
  padding: $account-tile-padding;
  margin: $account-tile-margin;
  box-shadow: $account-tile-box-shadow;

  @media screen and (max-width: 1023px) {
    display: block;
  }

  .points-display {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    transition: 100ms opacity;

    .avatar {
      float: none;
      overflow: hidden;
      text-align: center;
      color: $account-tile-avatar-color;
      border-radius: $account-tile-avatar-border-radius;
      width: $account-tile-avatar-width;
      height: $account-tile-avatar-height;
      margin: $account-tile-avatar-margin;

      a {
        text-decoration: none;
        cursor: pointer;
        display: block;
        height: 100%;
        width: 100%;
        color: $account-tile-avatar-color;
      }

      .initials {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $account-tile-avatar-font-family;
        font-size: $account-tile-avatar-font-size;
        font-weight: $account-tile-avatar-font-weight;
        line-height: $account-tile-avatar-line-height;
        background-color: $account-tile-avatar-background-color;
        border-radius: $account-tile-avatar-border-radius;
      }

      img {
        height: 100%;
        width: auto;
      }
    }

    .balance {
      display: inline-flex;
      align-items: center;
      color: $brand-primary-color;
      font-family: $account-tile-balance-font-family;
      font-weight: $account-tile-balance-font-weight;
      font-size: $account-tile-balance-font-size;
      line-height: $account-tile-balance-line-height;
      padding: $account-tile-balance-padding;
      margin: $account-tile-balance-margin;
      text-align: $account-tile-balance-text-align;

      a {
        cursor: pointer;
        text-decoration: none;
        color: #071D49 !important;
      }

      .val {
        color: $account-tile-balance-value-color;
        font-family: $account-tile-balance-value-font-family;
        font-weight: $account-tile-balance-value-font-weight;
        font-size: $account-tile-balance-value-font-size;
        line-height: $account-tile-balance-value-line-height;
        padding: $account-tile-balance-value-padding;
        margin: $account-tile-balance-value-margin;
      }
    }

    &.avatar-off {
      .balance {
        margin: auto;
      }
    }

    &.hidden-0 {
      opacity: 1;
    }

    &.hidden-10 {
      opacity: 1;
    }

    &.hidden-20 {
      opacity: 1;
    }

    &.hidden-30 {
      opacity: 1;
    }

    &.hidden-40 {
      opacity: 0.8;
    }

    &.hidden-50 {
      opacity: 0.7;
    }

    &.hidden-60 {
      opacity: 0.6;
    }

    &.hidden-70 {
      opacity: 0.5;
    }

    &.hidden-80 {
      opacity: 0.4;
    }

    &.hidden-90 {
      opacity: 0.4;
    }

    &.hidden-100 {
      opacity: 0.2;
    }
  }
}
