$slide-color: black;
$slide-background-color: white;
$slide-font-family: inherit;
$slide-font-size: 15px;
$slide-font-weight: normal;
$slide-line-height: inherit;
$slide-margin: 0px;

$slide-page-color: white;
$slide-page-active-color: white;
$slide-page-complete-color: white;
$slide-page-complete-active-color: white;
$slide-page-background-color: #b5b5b5;
$slide-page-active-background-color: black;
$slide-page-complete-background-color: #00b000;
$slide-page-complete-active-background-color: #006000;
$slide-page-border-radius: 16px;

$slide-page-height: 16px;
$slide-page-width: 16px;
$slide-page-font-family: $slide-font-family;
$slide-page-font-size: 15px;
$slide-page-font-weight: normal;
$slide-page-line-height: $slide-line-height;
$slide-page-margin: 0px 10px 20px 0px;

$slide-arrow-color: black;
$slide-arrow-width: 30px;
$slide-arrow-height: 40px;
$slide-arrow-thickness: 4px;
$slide-arrow-margin: 0px 20px;

$slide-duration: 300ms;
$slide-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

$carousel-hover-duration: 200ms;
$carousel-hover-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

@import '../../../app/q360-comps/carousel/src/carousel.scss';
