$clock-color: black !default;
$clock-background-color: white !default;
$clock-font-family: inherit !default;
$clock-font-size: 16px !default;
$clock-font-weight: normal !default;
$clock-line-height: inherit !default;
$clock-padding: 0px !default;
$clock-margin: 0px 0px 0px 0px !default;

$clock-arrow-color: $clock-color !default;
$clock-arrow-width: 40px !default;
$clock-arrow-height: 40px !default;
$clock-arrow-margin: 10px !default;

.clock {
  display: flex;
  align-items: center;
  align-content: center;
  background-color: $clock-background-color;
  padding: $clock-padding;
  margin: $clock-margin;

  .hour {
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      align-items: center;
      align-content: center;
      color: $clock-color;
      font-family: $clock-font-family;
      font-size: $clock-font-size;
      font-weight: $clock-font-weight;
      line-height: $clock-line-height;
      margin: 0px;
      padding: 0px;
      text-align: center;
    }
  }

  .seperator {
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      align-items: center;
      align-content: center;
      color: $clock-color;
      font-family: $clock-font-family;
      font-size: $clock-font-size;
      font-weight: $clock-font-weight;
      line-height: $clock-line-height;
      margin: 0px;
      padding: 0px;
      text-align: center;
    }
  }

  .minute {
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      align-items: center;
      align-content: center;
      color: $clock-color;
      font-family: $clock-font-family;
      font-size: $clock-font-size;
      font-weight: $clock-font-weight;
      line-height: $clock-line-height;
      margin: 0px;
      padding: 0px;
      text-align: center;
    }
  }

  .am-pm {
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      align-items: center;
      align-content: center;
      color: $clock-color;
      font-family: $clock-font-family;
      font-size: $clock-font-size;
      font-weight: $clock-font-weight;
      line-height: $clock-line-height;
      margin: 0px;
      padding: 0px;
      text-align: center;
    }
  }

  button.increment {
    display: inline-block;
    width: $clock-arrow-width;
    height: $clock-arrow-height;
    margin: $clock-arrow-margin;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0px;

    span:after {
      display: inline-block;
      content: '';
      width: 12px;
      height: 12px;
      border-left: $clock-arrow-color 3px solid;
      border-top: $clock-arrow-color 3px solid;
      transform: rotate(45deg);
      position: relative;
      left: 0px;
      top: 5px;
    }
  }

  button.decrement {
    display: inline-block;
    width: $clock-arrow-width;
    height: $clock-arrow-height;
    margin: $clock-arrow-margin;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0px;

    span:after {
      display: inline-block;
      content: '';
      width: 12px;
      height: 12px;
      border-right: $clock-arrow-color 3px solid;
      border-bottom: $clock-arrow-color 3px solid;
      transform: rotate(45deg);
      position: relative;
      left: 0px;
      top: -3px;
    }
  }
}
