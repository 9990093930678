.BIWModal {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    z-index: 20;
    flex-direction: column;
    top: 0;
    left: calc(50vw - 300px);
    margin: 10vh 0;
    width: 600px;
    @media screen and (max-width: 425px) {
      width: 300px;
      left: calc(50vw - 150px);
    }
  }
  &-content {
    padding: 20px;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4 {
    width: 100%;
  }
}

// These should be the default styles in the Nackle Overlay (implemented in the BIWModal)
.bp3-portal,
.bp3-overlay {
  z-index: 200;
}
