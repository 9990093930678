.site-content > .content-wrapper > .content {
  &.activity-history {
    max-width: 1280px;
  }
}

.content {
  &.activity-history,
  &.statement,
  &.pending-activities {
    .content-body {
      background: white;
      display: flex;
      flex-direction: column;

      .account-display-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 40px 60px;

        @media screen and (max-width: 1023px) {
          padding: 40px 20px;
          margin: 0 auto;
          flex-direction: column;
          text-align: center;
        }

        .points-display {
          display: inline-block;

          .avatar {
            float: none;
            height: 140px;
            width: 140px;
            border-radius: 70px;
            display: inline-block;
            vertical-align: middle;

            .initials {
              font-size: 48px;
              border-radius: 70px;
            }
          }

          .balance {
            font-size: 28px;
            vertical-align: middle;
            padding: 10px 28px;
            margin-right: 28px;
            border-right: 1px solid #d0d0d0;

            @media screen and (max-width: 1023px) {
              border-right: none;
              margin-right: 0px;
            }

            a {
              color: black;
            }

            .val {
              font-size: 46px;
              color: black;
            }
          }
        }

        .member-info {
          display: inline-block;
          margin-left: 12px;
          vertical-align: middle;

          @media screen and (max-width: 1023px) {
            display: block;
            margin-top: 20px;
            margin-left: 0;
          }

          .name {
            font-size: 22px;
          }
          .member-since {
            font-size: 14px;
            color: #d0d0d0;
          }
          .link {
            text-decoration: none;
            font-size: 16px;
            color: black;
            font-weight: bold;
            margin-top: 8px;
            display: block;
          }
        }

        .account-display-content-group {
          flex: 1 1 auto;
          display: flex;
          justify-content: flex-end;
        }

        .marketplace-pending-activities-container {
          flex: 1 1 auto;
          text-align: left;
          margin-top: 20px;

          @media screen and (min-width: 768px) {
            min-width: 340px;
            max-width: 340px;
          }

          @media screen and (min-width: 1024px) {
            margin-top: 0;
          }

          .marketplace {
            .marketplace-item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              flex-wrap: wrap;

              .marketplace-item-description {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1 1 auto;

                .icon-container {
                  flex-shrink: 0;
                  display: flex;
                  width: 45px;
                  height: 45px;
                  border-radius: 4px;
                  background-color: #e8e8e8;
                  color: $dark-grey;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    width: 24px;
                    fill: $dark-grey;
                  }
                }

                p {
                  flex: 1 1 auto;
                  margin: 0 0 0 8px;

                  strong {
                    color: $black;
                  }
                }
              }

              .button {
                margin: 8px 0 0 0;
                white-space: nowrap;

                @media screen and (min-width: 375px) {
                  margin-top: 0;
                  margin-left: 8px;
                }

                // For a very narrow viewport, reduce button width to prevent wrapping.
                @media screen and (min-width: 375px) and (max-width: 399px) {
                  padding: $button-padding-y 0.625rem;
                }

                // Override default button behavior to auto width.
                @media screen and (min-width: 375px) and (max-width: 767px) {
                  width: auto;
                  min-width: auto;
                }

                .icon {
                  display: inline-block;
                  width: 13px;
                  margin-left: 4px;
                  fill: $white;

                  @media screen and (min-width: 768px) {
                    margin-left: 8px;
                  }
                }
              }
            }
          }

          .pending-activities {
            border-top: 1px solid $light-grey;
            margin-top: 24px;
            padding-top: 24px;

            .pending-activities-summary {
              display: flex;
              justify-content: space-between;
              color: $black;
              margin-bottom: 12px;

              .points {
                font-weight: bold;
              }
            }

            .pending-activities-description {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }
      }

      .tabs {
        border-bottom: 1px solid #d0d0d0;
        margin: 0px 20px;
      }
      .tab {
        color: black;
        display: inline-block;
        border-left: 1px solid #d0d0d0;
        border-top: 1px solid #d0d0d0;
        border-right: 1px solid #d0d0d0;
        width: 208px;
        height: 48px;
        margin-bottom: -1px;
        background: white;
        border-bottom: 1px solid white;

        text-align: center;
        line-height: 48px;

        &.inactive {
          color: black;
          text-decoration: none;
          background: #e6e6e6;
          border-bottom: 1px solid #d0d0d0;
        }
      }
    }
  }
}

.content {
  &.activity-history {
    .content-body {
      .activity {
        margin: 0px 20px;
        padding-bottom: 38px;

        .filter {
          display: flex;
          // align-items: center;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0;
          padding: 16px;
          flex-direction: column;

          @media screen and (max-width: 1023px) {
            align-items: flex-start;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              margin: 0 0 0 10px;

              &.promotion {
                display: block !important;
              }

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              label {
                display: inline-flex;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      min-width: 60px;
                      margin-right: 0px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .date-time-input {
              display: inline-flex;
              width: 121px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 0px;
              }

              input {
                font-size: 16px;
                font-weight: normal;
                height: 36px;
                padding: 0px;
                margin: 0px 0px 0px 5px;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1023px) {
                  margin: 0px;
                  padding: 10px;
                }
              }

              .button-container {
                @media screen and (min-width: 1023px) {
                  width: 36px;
                }
                button {
                  padding: 6px 0px 2px 0px;
                }
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              width: 139px;
              max-width: 139px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                max-width: 100%;
              }
            }

            .button-container {
              display: flex;
            }
          }

          .export-buttons {
            display: inline-block;
            line-height: 1;
            flex-shrink: 0;

            @media screen and (max-width: 1023px) {
              margin-top: 15px;
            }

            .pdf-export {
              display: inline-block;
              width: 36px;
              height: 36px;
              margin-left: 10px;
              background: url('../../../assets/img/pdf-export.png');
              cursor: pointer;

              @media screen and (max-width: 1023px) {
                margin-left: 0;
              }
            }
            .csv-export {
              display: inline-block;
              width: 36px;
              height: 36px;
              margin-left: 10px;
              background: url('../../../assets/img/cvs-export.png');
              cursor: pointer;
            }
          }
        }

        .ReactTable {
          width: 100%;

          .rt-thead {
            min-width: auto !important;

            .rt-tr {
              .rt-th {
                /* Date */
                &:nth-child(1) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    flex-grow: 1 !important;
                    width: 121px !important;
                  }
                }

                /* Activity */
                &:nth-child(2) {
                  width: 51% !important;
                  flex-grow: 51 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    flex-grow: 1 !important;
                    width: calc(100% - 121px) !important;
                  }
                }

                /* Award */
                &:nth-child(3) {
                  width: 25% !important;
                  flex-grow: 25 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    display: none;
                  }
                }

                /* Status */
                &:nth-child(4) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    display: none;
                  }
                }
              }
            }
          }

          .rt-tbody {
            min-width: auto !important;

            .rt-tr {
              @media screen and (max-width: 1023px) {
                position: relative;
                flex-direction: column;
              }

              .rt-td {
                /* Date */
                &:nth-child(1) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    position: absolute;
                    width: 121px !important;
                    padding-bottom: 0px;
                  }
                }

                /* Activity */
                &:nth-child(2) {
                  width: 51% !important;
                  flex-grow: 51 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    width: calc(100% - 121px) !important;
                    margin-left: 121px;
                    padding-bottom: 0px;
                  }
                }

                /* Award */
                &:nth-child(3) {
                  width: 25% !important;
                  flex-grow: 25 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    width: calc(100% - 121px) !important;
                    margin-left: 121px;
                    padding-bottom: 0px;
                  }
                }

                /* Status */
                &:nth-child(4) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    flex-grow: 0 !important;
                    width: calc(100% - 121px) !important;
                    margin-left: 121px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.content {
  &.statement {
    .content-body {
      .statement {
        margin: 0px 20px;
        padding-bottom: 38px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .summary {
          display: inline-block;
          box-sizing: border-box;
          margin: 16px 20px 16px 0px;

          @media screen and (max-width: 1023px) {
            width: 100%;
            margin: 16px 0px 0px 0px;
          }

          .row {
            padding: 3px 4px;
            border-bottom: 1px solid #d0d0d0;
            display: flex;

            &:last-child {
              border: none;
            }

            td:first-child span {
              display: block;
              margin-right: 10px;
              flex-grow: 1;
            }

            td:last-child span {
              display: block;
              font-weight: bold;
              text-align: right;
              min-width: 50px;
            }
          }
        }

        .filter {
          flex: 1;
          display: flex;
          align-items: center;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0 auto 0;
          padding: 16px;

          @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin: 16px 0 16px 0;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              margin: 0 0px 0 10px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              label {
                display: inline-flex;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      margin-right: 0px;
                      min-width: 60px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .date-time-input {
              display: inline-flex;
              width: 121px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 0px;
              }

              input {
                font-size: 16px;
                font-weight: normal;
                height: 36px;
                padding: 0px;
                margin: 0px 0px 0px 5px;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1023px) {
                  margin: 0px;
                }
              }

              .button-container {
                button {
                  padding: 6px 0px 2px 0px;
                }
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
                flex: 1;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              width: 139px;
              max-width: 139px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                flex: 1;
              }
            }

            .button-container {
              display: flex;
              width: 36px;
            }
          }
        }

        .ReactTable {
          width: 100%;

          .rt-thead {
            min-width: auto !important;

            .rt-tr {
              .rt-th {
                /* Points */
                &:nth-child(1) {
                  width: 94px !important;
                  flex-grow: 8.6 !important;
                  white-space: normal;
                }

                /* Date */
                &:nth-child(2) {
                  width: calc(100% - 94px) !important;
                  flex-grow: 91.4 !important;
                  white-space: normal;
                }

                /* Description */
                &:nth-child(3) {
                  display: none;
                }
              }
            }
          }

          .rt-tbody {
            min-width: auto !important;

            .rt-tr {
              position: relative;
              // flex-direction: column;
              // min-height: 94px;

              .rt-td {
                /* Points */
                &:nth-child(1) {
                  position: absolute;
                  width: 94px !important;
                  flex-grow: 8.6 !important;
                  white-space: normal;
                }

                /* Date */
                &:nth-child(2) {
                  order: 0;
                  width: 30% !important;
                  flex-grow: 91.4 !important;
                  white-space: normal;
                  // padding: 16px 0px 0px 104px;
                  padding: 8px 0px 0px 104px;
                }

                /* Description */
                &:nth-child(3) {
                  order: 1;
                  width: 50% !important;
                  flex-grow: 91.4 !important;
                  white-space: normal;
                  // padding: 0px 0px 21px 104px;
                  padding: 8px 0px 21px 104px;
                }
              }
            }
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 20px;
          margin: 0 auto;
          border-radius: 10px;
          // background-color: #d0d0d0;
          text-align: center;
          flex-direction: column;

          span:first-child {
            display: block;
            font-size: 20px;
          }
          span:last-child {
            display: block;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.content {
  &.pending-activities {
    .content-body {
      .pending {
        margin: 0px 20px;
        padding-bottom: 38px;

        .filter {
          display: flex;
          // align-items: center;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0;
          padding: 16px;
          flex-direction: column;

          @media screen and (max-width: 1023px) {
            align-items: flex-start;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              margin: 0 0 0 10px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              label {
                display: inline-flex;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      min-width: 60px;
                      margin-right: 0px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .date-time-input {
              display: inline-flex;
              width: 121px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 0px;
              }

              input {
                font-size: 16px;
                font-weight: normal;
                height: 36px;
                padding: 0px;
                margin: 0px 0px 0px 5px;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1023px) {
                  margin: 0px;
                }
              }

              .button-container {
                width: 36px;
                button {
                  padding: 6px 0px 2px 0px;
                }
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              width: 139px;
              max-width: 139px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                max-width: 100%;
              }
            }

            .button-container {
              display: flex;
            }
          }

          .export-buttons {
            display: inline-block;
            line-height: 1;
            flex-shrink: 0;

            @media screen and (max-width: 1023px) {
              margin-top: 15px;
            }

            .pdf-export {
              display: inline-block;
              width: 36px;
              height: 36px;
              margin-left: 10px;
              background: url('../../../assets/img/pdf-export.png');
              cursor: pointer;

              @media screen and (max-width: 1023px) {
                margin-left: 0;
              }
            }
            .csv-export {
              display: inline-block;
              width: 36px;
              height: 36px;
              margin-left: 10px;
              background: url('../../../assets/img/cvs-export.png');
              cursor: pointer;
            }
          }
        }

        .ReactTable {
          width: 100%;

          .rt-thead {
            min-width: auto !important;

            .rt-tr {
              .rt-th {
                /* Date */
                &:nth-child(1) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    flex-grow: 1 !important;
                    width: 121px !important;
                  }
                }

                /* Activity */
                &:nth-child(2) {
                  width: 51% !important;
                  flex-grow: 51 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    flex-grow: 1 !important;
                    width: calc(100% - 121px) !important;
                  }
                }

                /* Award */
                &:nth-child(3) {
                  width: 25% !important;
                  flex-grow: 25 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    display: none;
                  }
                }

                /* Status */
                &:nth-child(4) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    display: none;
                  }
                }
              }
            }
          }

          .rt-tbody {
            min-width: auto !important;

            .rt-tr {
              @media screen and (max-width: 1023px) {
                position: relative;
                flex-direction: column;
              }

              .rt-td {
                /* Date */
                &:nth-child(1) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    position: absolute;
                    width: 121px !important;
                    padding-bottom: 0px;
                  }
                }

                /* Activity */
                &:nth-child(2) {
                  width: 51% !important;
                  flex-grow: 51 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    width: calc(100% - 121px) !important;
                    margin-left: 121px;
                    padding-bottom: 0px;
                  }
                }

                /* Award */
                &:nth-child(3) {
                  width: 25% !important;
                  flex-grow: 25 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    width: calc(100% - 121px) !important;
                    margin-left: 121px;
                    padding-bottom: 0px;
                  }
                }

                /* Status */
                &:nth-child(4) {
                  width: 12% !important;
                  flex-grow: 12 !important;
                  white-space: normal;

                  @media screen and (max-width: 1023px) {
                    flex-grow: 0 !important;
                    width: calc(100% - 121px) !important;
                    margin-left: 121px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

