// news_communications_block
.news_communications_block {
  .task-slide {
    .task > h1.title,
    .task > div.text {
      display: block;
    }
  }

  @media screen and (min-width: 1024px) {
    .task-container {
      display: flex;
      align-items: center;
    }
  }
}
