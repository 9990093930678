.flash-message {
  padding: 16px;
  box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.2);
  margin: -$site-content-vert-padding 0px $site-content-vert-padding 0px;

  &.success {
    background: lightgreen;
  }

  p {
    display: block;
    padding: 0px;
    margin: 0px;
  }
}
