@import '../../../app/q360-comps/image/src/image.scss';

$video-icon-color: white;
$video-icon-background-color: #d3d2d2;
$video-icon-size: 113px;
$video-icon-border-radius: 56px;

$video-full-screen-color: white;
$video-full-screen-background-color: #0d2349;
// $video-full-screen-size: 35px;
$video-full-screen-padding: 2px;
$video-full-screen-margin: 0px;

$video-icon-animate-duration: 200ms;
$video-icon-animate-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

@import '../../../app/q360-comps/video/src/video.scss';
