$radial-meter-size: 300 !default;
$radial-meter-bar-color: red !default;
$radial-meter-overflow-color: #880000 !default;
$radial-meter-trail-color: #aaa !default;
$radial-meter-bar-width: 20px !default;
$radial-meter-bar-shift: -5 !default;
$radial-meter-overflow-width: 10px !default;
$radial-meter-overflow-shift: 10 !default;
$radial-meter-trail-width: 30px !default;
$radial-meter-overlay-color: #888 !default;

$radial-meter-font-size: 36px !default;
$radial-meter-font-color: #ccc !default;

$_radial-meter-svg-artboard-size: 3;

@mixin radial-meter-font-size($px) {
  font-size: calc($_radial-meter-svg-artboard-size/$radial-meter-size) * $px;
}

//This mixin keeps the definition of a bars width and position in css.
//$shift will slide the bar in or out by the provided value in pixels.
//The value provided for $shift must be a number, not a px value.
@mixin radial-meter-stroke-width($px, $shift: 0) {
  $_artboard-ratio: calc($_radial-meter-svg-artboard-size/$radial-meter-size);
  $_scale: $shift * $_artboard-ratio + 1;
  transform: scale($_scale);
  stroke-width: $_artboard-ratio * $px * (2-$_scale);
}

@mixin radial-meter-position($x, $y) {
  $_artboard-ratio: calc($_radial-meter-svg-artboard-size/$radial-meter-size);
  transform: translate($_artboard-ratio * $x, $_artboard-ratio * $y);
}

.meter-component.radial-meter {
  width: $radial-meter-size + px;
  height: 368px;

  .val {
    @include radial-meter-font-size($radial-meter-font-size);
    text-anchor: middle;
    alignment-baseline: middle;

    fill: $radial-meter-font-color;
  }

  .bar,
  .meter-bg {
    stroke: $radial-meter-bar-color;
    // removed transform and stroke-width
  }

  .overflow-bar {
    stroke: $radial-meter-overflow-color;
    @include radial-meter-stroke-width($radial-meter-overflow-width, $radial-meter-overflow-shift);
  }

  /*
    .meter-bg {
        stroke: $radial-meter-trail-color;
        @include radial-meter-stroke-width($radial-meter-trail-width, $radial-meter-bar-shift);
        fill: $radial-meter-overlay-color;
    }
    */
}
