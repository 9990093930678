$wizard-step-list-padding: 20px !default;
$wizard-step-list-margin: 0px !default;

$wizard-step-padding: 10px 10px 0px 10px !default;

$wizard-step-inner-color: black !default;
$wizard-step-inner-size: 25px !default;
$wizard-step-inner-radius: calc($wizard-step-inner-size / 2) !default;

$wizard-step-inner-active-color: black !default;

$wizard-step-outer-color: transparent !default;
$wizard-step-outer-size: 37px !default;
$wizard-step-outer-thickness: 3px !default;
$wizard-step-outer-radius: calc($wizard-step-outer-size / 2) !default;

$wizard-step-outer-active-color: #616161 !default;

$wizard-step-text-color: black !default;
$wizard-step-text-font-family: inherit !default;
$wizard-step-text-font-weight: normal !default;
$wizard-step-text-font-size: 16px !default;
$wizard-step-text-line-height: inherit !default;
$wizard-step-text-margin: 10px 0px 0px 0px !default;

$wizard-step-text-active-color: black !default;

$wizard-spacer-color: #dadada !default;
$wizard-spacer-width: 142px !default;
$wizard-spacer-height: 6px !default;
$wizard-spacer-margin: 20px 0px 0px 0px !default;

$wizard-spacer-width-mobile: $wizard-spacer-width !default;
$wizard-spacer-height-mobile: $wizard-spacer-height !default;
$wizard-spacer-margin-mobile: $wizard-spacer-margin !default;

$wizard-step-change-duration: 600ms !default;
$wizard-step-change-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.wizard-container {
  .step-list {
    list-style: none;
    text-align: center;
    padding: $wizard-step-list-padding;
    margin: $wizard-step-list-margin;

    .step {
      display: inline-block;

      p {
        box-sizing: border-box;
        display: block;
        padding: $wizard-step-padding;
        margin: 0px;

        & > span:nth-child(1) {
          box-sizing: border-box;
          display: block;
          position: relative;
          margin: auto;
          background: $wizard-step-inner-color;
          width: $wizard-step-inner-size;
          height: $wizard-step-inner-size;
          border-radius: $wizard-step-outer-radius;

          &:after {
            box-sizing: border-box;
            content: '';
            width: $wizard-step-outer-size;
            height: $wizard-step-outer-size;
            border-radius: $wizard-step-outer-radius;
            border: solid $wizard-step-outer-thickness $wizard-step-outer-color;
            position: absolute;
            left: -6px;
            top: -6px;
            transition: $wizard-step-change-duration $wizard-step-change-easing opacity;
          }
        }

        & > span:nth-child(2) {
          display: block;
          text-align: center;
          color: $wizard-step-text-color;
          font-family: $wizard-step-text-font-family;
          font-weight: $wizard-step-text-font-weight;
          font-size: $wizard-step-text-font-size;
          line-height: $wizard-step-text-line-height;
          margin: $wizard-step-text-margin;
        }
      }

      &.active {
        p {
          & > span:nth-child(1) {
            background: $wizard-step-inner-active-color;

            &:after {
              border-color: $wizard-step-outer-active-color;
            }
          }

          & > span:nth-child(2) {
            color: $wizard-step-text-active-color;
          }
        }
      }
    }

    .step-spacer {
      display: inline-block;
      vertical-align: top;
      background-color: $wizard-spacer-color;
      width: $wizard-spacer-width;
      height: $wizard-spacer-height;
      margin: $wizard-spacer-margin;

      @media screen and (max-width: 1023px) {
        width: $wizard-spacer-width-mobile;
        height: $wizard-spacer-height-mobile;
        margin: $wizard-spacer-margin-mobile;
      }
    }
  }

  .wizard-content {
    position: relative;
    overflow: hidden;
    margin: -5px;
    padding: 5px;

    .wizard-pane {
      width: 100%;

      & > div {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-50%);
        transition-delay: calc($wizard-step-change-duration / 2);
        transition: calc($wizard-step-change-duration / 2) $wizard-step-change-easing all;

        &.active {
          position: static;
          visibility: visible;
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }
  }
}
