//*******************************************************************************************/
//*************** pages/main.scss - the starting file for global-defaults *******************/
//*******************************************************************************************/

.take-quiz {
  .secondary-btn {
    background-color: $white;
    border: none;
  }
}

.main-page {
  .userSection {
    background-color: #CCCCCC;
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    padding: 40px 124px 54px 124px;
  
    .welcomeSection {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1192px;

      .ragged-layout {
        margin: 0;
        width: 60%;

        .item-container {
          position: relative;
          margin: 0px auto;
          display: flex;
          max-width: 1192px;
          flex-flow: wrap;
          justify-content: space-between;

          .tile.large {
            .board-container-main {
              width: inherit;
              margin: 0;
            }
            .cropping-window-challenge{
              img {
                object-fit: none;
              }
            }
            .block-content.tile-content {
              flex-direction: column;
              top: 40%;
            }

            @media screen and (min-width: 1921px) {
              width: inherit;
            }
          }
        }

        @media screen and (max-width: 1023px) {
          width: 100%;
          padding-top: 32px;
        }
      }

      .userInfo {
        display: flex;
        flex-flow: column;
        width: 35%;
        font-family: $branded-font-stack;
        background-color: $white;

        .greetings-name {
          display: flex;
          flex-flow: column;
          padding: 30px 30px 40px 30px;
          font-size: 42px;
          font-family: $branded-font-stack;
          color: $brand-primary-color;

          @media screen and (max-width: 1023px) {
            display: block;
            padding: 20px 20px 40px 20px;
            font-size: 32px;

            .firstName {
              padding-left: 8px;
            }
          }

          @media  screen and (max-width: 767px) {
            display: flex;
            font-size: 36px;

            .firstName {
              padding-left: 0px;
            }
          }
        }

        .rewardsSection {
          display: flex;
          flex-flow: column;
          padding-left: 30px;
          padding-right: 30px;
          font-size: 16px;
          color: $black;

          .rewardsText {
            width: 100%;
            font-size: 28px;
            padding-bottom: 20px;

            @media screen and (max-width: 1023px) {
              font-size: 22px;
            }

            @media  screen and (max-width: 767px) {
              font-size: 24px;
            }
          }

          .totalPoints {
            display: flex;
            flex-flow: column;

            .totalPointsNumber{
              padding-bottom: 7px;
              font-size: 48px;
            }

            @media screen and (max-width: 1023px) {
              padding-right: 80px;
            }

            @media screen and (max-width: 767px) {
              padding-right: 0px;
            }
          }

          .newPoints {
            display: flex;
            flex-flow: column;

            .newPointsNumber{
              font-size: 48px;
            }
          }

          @media screen and (max-width: 1023px) {
            flex-flow: wrap;
            padding-left: 20px;
            padding-right: 20px;
          }

          @media  screen and (max-width: 767px) {
            flex-flow: column;
          }
        }

        .detailsLink{
          color: $brand-primary-color;
          font-size: 16px;
          padding: 22px 0px 30px 30px;

          @media screen and (max-width: 1023px) {
            padding: 0px 20px;
          }

          @media  screen and (max-width: 767px) {
            padding: 10px 20px;
          }
        }

        @media screen and (max-width: 1023px) {
          width: 100%;
          height: 264px;
        }

        @media  screen and (max-width: 767px) {
          height: 411px;
        }
      }

      @media screen and (max-width: 1023px) {
        flex-direction: column-reverse;
        
      }
    }

    .salesSection {
      width: 1192px;
      padding-top: 35px;

      .salesInfo {
        height: 376px;
        background-color: $white;
      }
    }

    @media screen and (max-width: 1023px) {
      padding: 32px 41px 44px 41px;
    }

    @media screen and (max-width: 767px) {
      padding: 12px 20px 34px 20px;
    }
  }

  .tileSection {

    .ragged-layout {
      margin-top: 40px;
      margin-bottom: 48px;

      .item-container {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        max-width: 1192px;

        @media screen and (min-width: 1440px) {
          margin: 0px auto;
        }

        @media screen and (max-width: 1439px) {
          margin: 0px 124px !important;
        }

        @media screen and (max-width: 1150px) {
          grid-template-columns: repeat(2, 1fr);
          margin: 0px auto !important;
          padding: 0px 124px;
        }

        @media screen and (max-width: 1023px) {
          grid-template-columns: repeat(2, 1fr);
          margin: 0px auto !important;
          padding: 0px 41px;
        }

        @media screen and (max-width: 767px) {
          display: block;
          padding: 0px 20px;
        }
      }
    }
  }
}
