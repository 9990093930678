.content {
  &.message-center {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .content-body {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 0 20px;
      flex-grow: 1;

      .filter-container {
        background: #f1f1f1;
        box-sizing: border-box;
        display: block;
        margin: 16px 0;
        padding: 16px;

        & > p {
          display: inline-block;
          margin: 0px;
          padding: 0px 0px 0px 0px;
          font-size: 16px;

          @media screen and (max-width: 1023px) {
            display: block;
          }
        }

        form {
          display: inline-flex;

          @media screen and (max-width: 1023px) {
            display: flex;
            flex-direction: column;
          }

          .form-group {
            margin: 0px 0px 0px 10px;

            @media screen and (max-width: 1023px) {
              display: block;
              margin: 10px 0px 0px 0px;
              width: 100%;
            }
          }

          .form-group label > span,
          .form-group legend > span {
            display: inline-block;
            font-size: 16px;
          }

          .date-time-input {
            display: inline-flex;
            width: 121px;

            @media screen and (max-width: 1023px) {
              width: 100%;
            }

            input {
              font-size: 16px;
              font-weight: normal;
              height: 36px;
              padding: 0px;
              margin: 0px 0px 0px 5px;
              background-color: transparent;
              border: none;

              @media screen and (max-width: 1023px) {
                margin: 0px;
              }
            }
          }

          select {
            box-sizing: border-box;
            font-family: 'adelle-sans', sans-serif;
            font-size: 16px;
            font-weight: normal;
            width: 100%;
            height: 36px;
            margin: 0px;
          }
        }
      }

      .message-container {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;

        & > div {
          box-sizing: border-box;
          border-bottom: 1px solid #ebebeb;
          padding: 15px;

          & > .date {
            width: 70px;
            height: 70px;
            border-radius: 10px;
            background-color: #d0d0d0;
            margin: 0px 15px 0px 0px;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            vertical-align: top;
            position: relative;

            & > span:nth-child(1) {
              font-size: 24px;
            }

            & > span:nth-child(2) {
              font-size: 12px;
            }

            & > span:nth-child(3) {
              color: white;
              position: absolute;
              background: #404040;
              left: 0px;
              top: 0px;
              padding: 0 3px;
              font-size: 12px;
            }
          }

          & > .message {
            display: inline-flex;
            width: calc(100% - 85px);
            flex-direction: column;
            vertical-align: top;

            & > span:nth-child(1) {
              font-size: 16px;
              font-weight: bold;
            }

            & > span:nth-child(2) {
              font-size: 16px;
              display: flex;

              & > span {
                flex: 1;

                &,
                & > * {
                  word-break: break-all;
                  word-break: break-word;
                }
              }
            }

            & > button,
            & > .button {
              display: inline-block;
              border: none;
              background: transparent;
              color: black;
              font-size: 18px;
              font-weight: bold;
              padding: 0px;
              margin: 15px auto 0px 0px;
              text-align: left;
              width: auto;
              height: auto;
              box-shadow: none;
              transform: none;
              transition: none;
              cursor: pointer;

              &:hover {
                box-shadow: none;
                transform: none;
                transition: none;
              }
            }
          }

          &.collapsed {
            & > .message {
              & > span:nth-child(2) {
                display: flex;

                & > span {
                  flex: 1;

                  &,
                  & > * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    word-break: break-word;
                  }
                }
              }

              & > button,
              &.button {
                display: none;
              }
            }
          }

          &.expanded {
            & > .date {
              & > span:nth-child(3) {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}
