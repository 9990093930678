$no-tiles-color: black;
$no-tiles-font-family: inherit;
$no-tiles-font-weight: normal;
$no-tiles-font-size: 30px;
$no-tiles-line-height: inherit;
$no-tiles-text-align: center;

$tile-background-color: white;
$tile-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.2);

@import '../../../app/q360-comps/render-tiles/src/render-tiles.scss';

$item-transition-duration: 300ms;
$item-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

@import '../../../app/q360-comps/ragged-layout/src/ragged-layout.scss';

$flag-color: #ffffff;
$flag-background-color: #6a6a6a;
$flag-shadow-color: #252525;
$flag-height: 68px;
$flag-width: 68px;

$flag-header-font-family: inherit;
$flag-header-font-size: 21px;
$flag-header-font-weight: bold;
$flag-header-line-height: 1.4;
$flag-header-margin: 0px;

$flag-text-font-family: inherit;
$flag-text-font-size: 11px;
$flag-text-font-weight: normal;
$flag-text-line-height: 1;
$flag-text-margin: 0px;

$flag-appendage-width: 7px;

@import '../../../app/q360-comps/flag/src/flag.scss';

.flag .wrapper {
  visibility: hidden;
  transition: clip-path 0.3s;

  &.show {
    visibility: visible;
  }

  .large.reverse & {
    clip-path: inset(0px -7px -7px 100%);
    &.show {
      clip-path: inset(0px -7px -7px 0%);
    }
  }

  .large:not(.reverse) & {
    clip-path: inset(0px 100% -9px -7px);
    &.show {
      clip-path: inset(0px 0% -9px -7px);
    }
  }

  .small & {
    clip-path: inset(0px 100% -9px -7px);
    &.show {
      clip-path: inset(0px 0% -9px -7px);
    }
  }
}

@media screen and (min-width: 1024px) {
  .tile:not(.reverse),
  .tile.reverse {
    &.large {
      .promotion.banner {
        & > .media {
          width: 100%;
        }
      }
      .promotion > .content:after {
        display: none;
      }
    }
  }
}

/** ragged-layout.jsx should be manually applying the appropriate styles inline
but just in case we will set a default here. */
.tile.large {
  width: 100%;
  &.standalone {
    transform: unset;
    -ms-transform: unset;
  }
}
/** For ultrawide screens. */
@media screen and (min-width: 1921px) {
  .tile.large {
    width: 1920px;
    margin: 0 auto;

    &.standalone {
      width: 100%;
      margin: unset;
    }
  }
}
/** Go full width and center based on item-container which has width of 1280px
@media screen and (max-width: 1920px) and (min-width: 1280px) {
  .tile.large {
    width: 100vw;
    transform: translateX(calc((1280px - 100vw) / 2));
  }
}
*/

// Button container
.tile.large .promotion .is-media-background-active .form-group.submit {
  // On main page go to left
  text-align: left;
  .button.go {
    width: auto;
  }
}
.tile .promotion.banner > .media > div img {
  width: 90%;
}
.tile.small .promotion.banner > .media > div img.banner-promo-image {
  width: 90%;
  max-width: 364px;
  // Following media queries are used to make the banner size
  // match closer to power-ups/ single block promos
  @media screen and (max-width: 499px) {
    max-height: 218px;
  }
  @media screen and (min-width: 500px) {
    min-height: 210px;
  }
  @media screen and (min-width: 630px) {
    min-height: 226px;
  }
  @media screen and (max-width: 767px) {
    max-height: 222px;
  }
  @media screen and (min-width: 768px) {
    min-height: 254px;
  }
  @media screen and (min-width: 1024px) {
    min-height: 240px;
  }
  @media screen and (min-width: 1200px) {
    height: 258px;
  }
}

.tile.large .promotion .is-media-background-inactive .task-container .task-slide-container {
  .task,
  .task-slide {
    margin-top: 0px;
    height: auto;

    // .task.complete {
    //   @media screen and (min-width: 1024px) {
    //     display: flex;
    //   }
    // }
  }
}
