$progress-meter-size: 300 !default;
$progress-meter-bar-color: #59be3a !default;
$progress-meter-overflow-color: #000000 !default;
$progress-meter-trail-color: #aaa !default;
$progress-meter-bar-width: 20px !default;
$progress-meter-bar-shift: -5 !default;
$progress-meter-overflow-width: 10px !default;
$progress-meter-overflow-shift: 10 !default;
$progress-meter-trail-width: 30px !default;
$progress-meter-overlay-color: #888 !default;

$progress-meter-font-size: 36px !default;
$progress-meter-font-color: #ccc !default;

$_progress-meter-svg-artboard-size: 3;

@mixin progress-meter-font-size($px) {
  font-size: calc($_progress-meter-svg-artboard-size/$progress-meter-size) * $px;
}

//This mixin keeps the definition of a bars width and position in css.
//$shift will slide the bar in or out by the provided value in pixels.
//The value provided for $shift must be a number, not a px value.
@mixin progress-meter-stroke-width($px, $shift: 0) {
  $_artboard-ratio: calc($_progress-meter-svg-artboard-size/$progress-meter-size);
  $_scale: $shift * $_artboard-ratio + 1;
  transform: scale($_scale);
  stroke-width: $_artboard-ratio * $px * (2-$_scale);
}

@mixin progress-meter-position($x, $y) {
  $_artboard-ratio: calc($_progress-meter-svg-artboard-size/$progress-meter-size);
  transform: translate($_artboard-ratio * $x, $_artboard-ratio * $y);
}

.meter-component.progress-meter {
  width: $progress-meter-size + px;
  height: $progress-meter-size + px;

  .val {
    @include progress-meter-font-size($progress-meter-font-size);
    text-anchor: middle;
    alignment-baseline: middle;

    fill: $progress-meter-font-color;
  }

  .bar {
    stroke: $progress-meter-bar-color;
    @include progress-meter-stroke-width($progress-meter-bar-width, $progress-meter-bar-shift);
  }

  .overflow-bar {
    stroke: $progress-meter-overflow-color;
    @include progress-meter-stroke-width($progress-meter-overflow-width, $progress-meter-overflow-shift);
  }

  .meter-bg {
    stroke: $progress-meter-trail-color;
    @include progress-meter-stroke-width($progress-meter-trail-width);
    fill: $progress-meter-overlay-color;
  }
}

.progress-meters {
  position: relative;
  top: -25px;
  left: 20px;
  @media screen and (max-width: 630px) {
    top: 0px;
  }
}
.progress-meter-text {
  font-family: Arial, Helvetica, sans-serif;
  width: 177px;
  text-align: center;
  position: absolute;
  display: block;
  position: absolute;
  left: calc(100% - 225px);
  top: 35px;
  bottom: 0px;
  order: 3;
  pointer-events: none;

  @media screen and (max-width: 1023px) {
    left: calc(50% + 10px);
  }
  @media screen and (max-width: 630px) {
    left: calc(32% + 10px);
    top: 55px;
  }
}
.progress-meter-text .completed-text {
  color: #59be3a;
  font-size: 18px;
}
.progress-meter-text .completed-count {
  color: #59be3a;
  font-size: 40px;
}
.progress-meter-text .total {
  color: #ffffff;
  font-size: 40px;
}
.progress-meter-text .pending {
  color: #d08125;
  font-size: 18px;
}
