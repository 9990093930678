$video-icon-color: white !default;
$video-icon-background-color: #d3d2d2 !default;
$video-icon-size: 72px !default;
$video-icon-border-radius: 56px !default;

$video-full-screen-color: white !default;
$video-full-screen-background-color: #0d2349 !default;
$video-full-screen-size: 35px !default;
$video-full-screen-padding: 2px !default;
$video-full-screen-margin: 0px !default;

$video-icon-animate-duration: 200ms !default;
$video-icon-animate-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.video-container {
  position: relative;

  .img-split {
    height: 100%;
    display: flex;

    .secondary.watch-video {
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }

    .vid-mod {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 50;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .db {
      display: block;
    }
    .dn {
      display: none;
    }

    .closeBtn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 2%;
      font-size: 4rem;
      color: #eee;
      z-index: 10;
    }

    .modal-video-content {
      background: #fff;
      margin: 10% auto;
      // padding: 20px;
      width: 50%;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  video {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  .full-screen {
    position: absolute;
    width: $video-full-screen-size;
    height: $video-full-screen-size;
    padding: 8px;
    border: none;
    background-color: rgba(0, 0, 0, 0.7);
    margin: $video-full-screen-margin;

    svg {
      fill: $video-full-screen-color;
      width: 17px;
      height: 17px;
    }
    bottom: 10px;
    right: 10px;
    @media screen and (min-width: 768px) {
      right: 10px;
    }
  }

  .video-overlay {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;

    .video-icon {
      width: 72px;
      height: 72px;
      margin: auto;
      text-align: center;
      border-radius: $video-icon-border-radius;
      opacity: 0.85;
      background-color: #AD1AAC;

      svg {
        height: 100%;
        width: auto;
        fill: $video-icon-color;
      }
    }

    .video-icon-animate-enter {
      opacity: 0;
      transition: $video-icon-animate-duration $video-icon-animate-easing all;
    }

    .video-icon-animate-enter.video-icon-animate-enter-active {
      opacity: 1;
    }

    .video-icon-animate-leave {
      opacity: 1;
      transition: $video-icon-animate-duration $video-icon-animate-easing all;
    }

    .video-icon-animate-leave.video-icon-animate-leave-active {
      opacity: 0;
    }
  }
  button[data-state='subtitles'],
  button[data-state='subtitlesread'] {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    border: none;
    position: absolute;
    right: 100px;
    cursor: pointer;
    padding: 8px;

    margin-bottom: 0;
    height: 35px;
    width: auto;
    bottom: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    @media screen and (min-width: 768px) {
      right: 100px;
    }
  }
  button[data-state='subtitlesread'] {
    text-decoration: underline;
  }
  button[data-state='subtitles'] {
    text-decoration: none;
  }
  button[data-state='subtitles']:hover {
    background-color: #6a6a6a;
    border: 2px solid #6a6a6a;
  }
}
