.tile {
  &.large,
  &.small {
    .task {
      &.file {
        p.expires {
          @media screen and (max-width: 1023px) {
            text-align: center;
          }
        }
        .file-task-btn-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 1024px) {
            flex-direction: row;
          }
        }
      }
    }
  }
}
