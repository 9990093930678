.content {
  &.merch {
    .wizard-container {
      padding-top: 40px;

      .step-list {
        display: none;
      }
    }

    .wizard-content {
      width: 450px;
      padding: 0px 2px;
      margin: 0px auto;
      box-sizing: border-box;

      h2 {
        a {
            color: #071D49;
            text-decoration: underline;
        }
      }

      @media screen and (max-width: 1023px) {
        width: 100%;
        padding: 20px;
      }
    }

    label.radio {
      display: flex;
      align-items: center;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc( 100% - 30px );
      }
    }

    .form-group {
      &.state {
        float:left;
        width: calc(50% - 5px);
        margin-right: 5px;

        select {
          width: 100%;
          min-width: auto;
        }
      }

      // all these may not be necessary, but how many browsers do you want to test to find out?
      &.application-models-user-profile\[postal-code\],
      &.application-models-user-profile\[postal_code\],
      &.application-models-user-profile\[postalcode\],
      &.application-models-user-profile\[zip-code\],
      &.application-models-user-profile\[zip_code\],
      &.application-models-user-profile\[zipcode\],
      &.application-models-user-profile\[zip\],
      &.postal-code,
      &.postal_code,
      &.postalcode,
      &.zip-code,
      &.zip_code,
      &.zipcode,
      &.zip {
        display: inline-block;
        width: calc(50% - 5px);
        margin-left: 5px;
      }

      &.submit {
        margin: 10px auto;
        padding: 5px 0px 40px 0px;
        border-top: solid 1px #bbbbbb;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 1023px) {
          width: 100%;
          flex-direction: column-reverse;
          padding-bottom: 0px;
        }

        button {
          width: 100%;
          margin: 15px 0px 0px 0px;

          &:first-child {
            margin-right: 10px;

            @media screen and (max-width: 1023px) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
