.site-content > .content-wrapper .content {
  &.terms-and-conditions {
    max-width: 1280px;
    padding: 36px 65px;

    @media screen and (max-width: 1023px) {
      padding: 36px;
    }

    .form-group {
      &.submit {
        @media screen and (max-width: 1023px) {
          button,
          .button {
            display: block;
          }
        }
      }
    }
  }
}
