$radial-meter-size: 274;

$radial-meter-bar-width: 22px;
$radial-meter-bar-shift: -2;
$radial-meter-overflow-width: 9px;
$radial-meter-overflow-shift: 13;
$radial-meter-trail-width: 35px;

$radial-meter-bar-color: #ef9021;
$radial-meter-overflow-color: #da2727;
$radial-meter-overlay-color: #2c2c2c;
$radial-meter-trail-color: #525252;

$radial-meter-font-size: 51px;
$radial-meter-font-color: #fff;

$progress-meter-size: 274;

$progress-meter-bar-width: 22px;
$progress-meter-bar-shift: -2;
$progress-meter-overflow-width: 9px;
$progress-meter-overflow-shift: 13;
$progress-meter-trail-width: 35px;

$progress-meter-bar-color: #ef9021;
$progress-meter-overflow-color: #da2727;
$progress-meter-overlay-color: #2c2c2c;
$progress-meter-trail-color: #525252;

$progress-meter-font-size: 51px;
$progress-meter-font-color: #fff;

$state-meter-height: 44px;
$state-meter-width: 225px;

@import '../../../app/q360-comps/radial-meter/src/radial-meter.scss';
@import '../../../app/q360-comps/progress-meter/src/progress-meter.scss';
@import '../../../app/q360-comps/gauge-meter/src/gauge-meter.scss';
@import '../../../app/q360-comps/linear-meter/src/linear-meter.scss';

.meter-tile {
  display: flex;
  width: 100%;
  height: 314px;
  padding: 20px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-color: $meter-tile-bg-color;

  @media screen and (max-width: 1023px) {
    height: auto;
    flex-direction: column;
  }

  .meter {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 274px;
    font-size: 23px;
    color: $meter-display-font-color;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }

    @media screen and (max-width: 630px) {
      height: auto;
    }

    .title {
      display: block;
      width: calc(100% - 245px);
      flex-shrink: 0;
      order: 0;
      font-size: 36px;
      color: #757575;
      white-space: normal;
      word-wrap: break-word;
      text-align: right;
      max-height: 147px;
      overflow: hidden;
      margin: 0;
      font-weight: normal;

      @media screen and (max-width: 1023px) {
        width: calc(50% - 10px);
      }

      @media screen and (max-width: 630px) {
        width: 100%;
        text-align: center;
      }
    }

    .asOf {
      display: block;
      width: calc(100% - 245px);
      font-size: 16px;
      flex-shrink: 0;
      order: 1;
      color: $meter-asof-font-color;
      text-align: right;

      @media screen and (max-width: 1023px) {
        width: calc(50% - 10px);
      }
      @media screen and (max-width: 630px) {
        width: 100%;
        text-align: center;
      }
    }

    .meter-display-wrap {
      display: block;
      width: calc(100% - 245px);
      flex: 1;
      order: 2;
      overflow-y: auto;
      text-align: right;

      @media screen and (max-width: 1023px) {
        width: calc(50% - 10px);
      }
      @media screen and (max-width: 630px) {
        width: 100%;
        flex: auto;
        text-align: center;
      }
    }

    .meter-component {
      display: block;
      position: absolute;
      left: calc(100% - 225px);
      top: 0px;
      bottom: 0px;
      order: 3;
      pointer-events: none;

      @media screen and (max-width: 1023px) {
        left: calc(50% + 10px);
      }

      @media screen and (max-width: 630px) {
        position: static;
        left: auto;
        top: auto;
        bottom: auto;
        right: auto;
        max-width: 100%;
        order: 3;
        margin: 20px auto;
      }
    }

    &.none {
      .title {
        margin-left: auto;
        margin-right: auto;
      }

      .asOf {
        margin-left: auto;
        margin-right: auto;
      }

      .meter-display-wrap {
        margin-left: auto;
        margin-right: auto;
      }

      .meter-component {
        display: none;
      }
    }

    .meter-component.animated-meter {
      height: 476px;
      width: 300px;
      transform: translate(-57px, -131px);

      @media screen and (max-width: 630px) {
        transform: translate(-20px, -131px);
        margin-bottom: -231px;
      }
    }

    .meter-component.gauge-meter {
      width: 202px;
      height: 202px;
      transform: translate(10px, 0px);

      @media screen and (max-width: 630px) {
        transform: none;
      }
    }

    .meter-component.milestone-meter {
      width: 363px;
      height: 300px;
      transform: translate(-70px, -52px);

      @media screen and (max-width: 630px) {
        transform: translate(0px, -52px);
        margin-bottom: -52px;
      }
    }

    .meter-component.linear-meter {
      width: 225px;
      text-align: center;

      svg {
        width: 196px;
        height: 231px;
        overflow: visible;

        @media screen and (max-width: 1023px) {
          transform: translateX(17px);
        }
      }
    }

    .meter-component.radial-meter {
      width: 254px;
      height: 254px;
      transform: translate(-14px, -26px);

      text.label {
        transform: none;
      }

      @media screen and (max-width: 630px) {
        transform: translate(0px, -26px);
        margin-bottom: -26px;
      }
    }

    .meter-component.progress-meter {
      width: 210px;
      height: 210px;
      transform: translate(-14px, -26px);

      text.label {
        transform: none;
      }

      @media screen and (max-width: 630px) {
        transform: translate(0px, -26px);
        margin-bottom: -26px;
      }
    }
    .progress-meters {
      .progress-meter-text .completed-text {
        color: #59be3a;
        font-size: 18px;
      }
      .progress-meter-text .completed-count {
        color: #59be3a;
        font-size: 40px;
      }
      .progress-meter-text .total {
        color: #ffffff;
        font-size: 40px;
      }
      .progress-meter-text .pending {
        color: #d08125;
        font-size: 18px;
      }
      .arcSplitCompleted {
        fill: #59be3a;
      }
      .arcSplitPending {
        fill: #ef9021;
      }
      .arcSplitTotal {
        fill: #2c2c2c;
      }
      .inner-circle,
      .background-circle {
        fill: #525252;
      }
      .inner-circles {
        fill: #2c2c2c;
      }
    }

    .milestone-meter {
      @media screen and (max-width: 1023px) {
        z-index: 0;
      }
    }

    .linear-meter {
      .level {
        &:nth-child(2) {
          display: none; /* Q360 levels start at 0, and this shouldn't be displayed */
        }
      }
    }

    .animated-meter {
      z-index: 35;

      @media screen and (max-width: 1023px) {
        z-index: 0;
      }

      .background .banner {
        transform: translate(-71px, -158px);
        fill: #373737;
        stroke: #525252;
        stroke-width: 10px;
      }

      .val {
        fill: white;
        font-size: 51.7px;
        text-anchor: middle;
        transform: translate(20px, 0px);
      }

      .label {
        fill: white;
        font-size: 17.5px;
        text-anchor: middle;
        transform: translate(20px, 20px);
      }

      &.varient-0 {
        .spritePos {
          z-index: 100000;
          transform: scale(0.6) translate(33px, -155px);
        }

        .st0 {
          fill: #f4b400;
        }

        .st1 {
          fill: #ffda03;
        }

        .st2 {
          fill: #ea9c00;
        }

        .trigger .coin {
          animation: rotate 0.3s linear 0s 2.43333;
        }

        .trigger {
          animation: jump 0.7s cubic-bezier(0.41, 1.62, 0.94, 1.16) 0s, fade 0.245s linear 0.5s;
          animation-fill-mode: forwards;
        }

        @keyframes rotate {
          0% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(-1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }

        @keyframes jump {
          0% {
            transform: translate(0px, 0px);
          }
          100% {
            transform: translate(0px, -125px);
          }
        }

        @keyframes fade {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }

        // was causing css warning in Firefox: "Keyframe rule ignored due to bad selector."
        // it doesn't appear to be getting used in this file, even the
        // @keyframes test {
        //     from {
        //         opacity: 0;
        //         transform: rotate(10), translate(15, 15);
        //         display: none;
        //         position: absolute;
        //     }
        //     to {
        //         opacity: 1;
        //         transform: rotate(70), translate(0, 0);
        //         display: block;
        //         position: fixed;
        //     }
        // }
      }

      &.varient-1 {
        $t: 1;
        $d: 0;
        $colors: #6bd9e7, #eee722, #f4b400, #da2727, #c29049;

        .spritePos {
          z-index: 100000;
          transform: scale(1.4) translate(16px, -70px);
        }

        .star {
          &.color0 {
            animation: spin0 $t + s $d + s, toColor0 $t + s linear $d + s;
            animation-fill-mode: forwards;
          }
          &.color1 {
            animation: spin1 $t + s $d + s, toColor1 $t + s linear $d + s;
            animation-fill-mode: forwards;
          }
          &.color2 {
            animation: spin2 $t + s $d + s, toColor2 $t + s linear $d + s;
            animation-fill-mode: forwards;
          }
          &.color3 {
            animation: spin0 $t + s $d + s, toColor3 $t + s linear $d + s;
            animation-fill-mode: forwards;
          }
          &.color4 {
            animation: spin1 $t + s $d + s, toColor4 $t + s linear $d + s;
            animation-fill-mode: forwards;
          }
        }

        @keyframes spin0 {
          0% {
            transform: scale(1, 1);
          }
          10% {
            transform: scale(-1, 1);
          }
          20% {
            transform: scale(1, 1);
          }
          30% {
            transform: scale(-1, 1);
          }
          40% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(-1, 1);
          }
          60% {
            transform: scale(1, 1);
          }
          70% {
            transform: scale(-1, 1);
          }
          80% {
            transform: scale(1, 1);
          }
          90% {
            transform: scale(-1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }
        @keyframes spin1 {
          0% {
            transform: scale(1, 1);
          }
          10% {
            transform: scale(-1, 1);
          }
          20% {
            transform: scale(1, 1);
          }
          30% {
            transform: scale(-1, 1);
          }
          40% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(-1, 1);
          }
          60% {
            transform: scale(1, 1);
          }
          70% {
            transform: scale(-1, 1);
          }
          80% {
            transform: scale(1, 1);
          }
          90% {
            transform: scale(-1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }
        @keyframes spin2 {
          0% {
            transform: scale(1, 1);
          }
          10% {
            transform: scale(-1, 1);
          }
          20% {
            transform: scale(1, 1);
          }
          30% {
            transform: scale(-1, 1);
          }
          40% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(-1, 1);
          }
          60% {
            transform: scale(1, 1);
          }
          70% {
            transform: scale(-1, 1);
          }
          80% {
            transform: scale(1, 1);
          }
          90% {
            transform: scale(-1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }
        @keyframes toColor0 {
          from {
            fill: nth($colors, 5);
          }
          to {
            fill: nth($colors, 1);
          }
        }
        @keyframes toColor1 {
          from {
            fill: nth($colors, 1);
          }
          to {
            fill: nth($colors, 2);
          }
        }
        @keyframes toColor2 {
          from {
            fill: nth($colors, 2);
          }
          to {
            fill: nth($colors, 3);
          }
        }
        @keyframes toColor3 {
          from {
            fill: nth($colors, 3);
          }
          to {
            fill: nth($colors, 4);
          }
        }
        @keyframes toColor4 {
          from {
            fill: nth($colors, 4);
          }
          to {
            fill: nth($colors, 5);
          }
        }
      }
    }
  }

  &.double {
    .meter {
      &:nth-child(1) {
        margin-right: 10px;
      }

      &:nth-child(2) {
        margin-left: 10px;
      }

      @media screen and (max-width: 1023px) {
        &:nth-child(1) {
          margin-right: 0px;
          margin-bottom: 10px;
        }

        &:nth-child(2) {
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
  }

  .label {
    @include radial-meter-font-size(17px);
    fill: $radial-meter-font-color;
    text-anchor: middle;

    @include radial-meter-position(0, 20px);
  }

  .percent {
    font-size: 0.7em;
  }

  .world {
    stroke: #525252;
    stroke-width: 7px;
  }

  .sprite {
    fill: #eee722;
  }
}

.group-meter-tile {
  height: auto;
  padding: 25px 0 20px 0;

  .title {
    color: #f7a800;
    font-size: 27px;
    font-family: inherit;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    display: block;
    padding-bottom: 12px;
  }

  .meter-display-wrap * {
    color: #1baade;
    font-family: inherit;
    font-size: 20px;
  }

  text {
    font-size: 20px;
    fill: white;
    font-family: inherit;
    font-weight: bold;
  }

  .labelText text {
    fill: #ffffff;
  }

  text.in-progress-meter {
    fill: #f7a800;
    font-size: 19px;
  }

  tspan {
    font-size: 29px;
  }

  .meter-display-wrap {
    margin-top: 22px;
    padding: 0 8px;
    text-align: center;
    font-size: 20px;
    color: white;

    a {
      color: #1baade;
    }
  }
}

.group-meter-tile.single {
  .group-meter-container {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    margin-left: 35px;

    // this image is no longer in centene, I'm not sure they ever WERE inside centene.
    // I have no idea where one might look for them.
    // Perhaps investigate Demo-Skin master branch
    // background-image: url('../../../assets/img/group-meter-tile-background-image.svg');
    background-repeat: no-repeat;
    background-position: 18px;
  }
  .state-meter {
    height: $state-meter-height;
    width: $state-meter-width;
    padding: 3px 0;
  }
}

.group-meter-tile.double {
  background-color: white;

  .group-meter-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 35px;
  }

  .group-container,
  .group-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .group-container {
    // this image is no longer in centene, I'm not sure they ever WERE inside centene.
    // I have no idea where one might look for them.
    // Perhaps investigate Demo-Skin master branch
    // background-image: url('../../../assets/img/group-meter-tile-background-image.svg');
    background-repeat: no-repeat;
    background-position: center;

    margin-right: 4px;
  }

  .group-text-container {
    font-size: 20px;
    color: #999999;
    padding-left: 18px;
  }

  .state-meter {
    height: $state-meter-height;
    width: 44px;
    padding: 3px 0;
  }

  .meter-display-wrap {
    color: #084c85;
  }

  .jumbotron {
    text-align: center;
    font-size: 72px;
    line-height: 0.7em;
    font-weight: bold;
    color: #084c85;

    .lead {
      font-size: 20px;
      line-height: 1em;
      display: block;
    }
  }
}
