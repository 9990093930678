.social-media-nav {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      display: inline-block;
      margin: 0px 4px;
      padding: 0px;

      a {
        display: block;
        width: 36px;
        height: 36px;
        background: url('../../../../assets/img/social-media-sheet.png') top left no-repeat;
      }

      &.facebook {
        a {
          background-position: top left;
        }
      }

      &.gplus {
        a {
          width: 32px;
          margin: 0px 2px;
          background-position: -36px 0px;
        }
      }

      &.instagram {
        a {
          background-position: -68px 0px;
        }
      }

      &.linkedin {
        a {
          width: 45px;
          margin: 0px -9px 0px 0px;
          background-position: -105px 0px;
        }
      }

      &.pintrest {
        a {
          background-position: -150px 0px;
        }
      }

      &.twitter {
        a {
          background-position: -187px 0px;
        }
      }

      &.youtube {
        a {
          background-position: -224px 0px;
        }
      }
    }
  }
}
