$wizard-step-list-padding: 20px;
$wizard-step-list-margin: 0px;

$wizard-step-padding: 10px 10px 0px 10px;

$wizard-step-active-background-color: black;
$wizard-step-active-border-color: #616161;

$wizard-step-inner-color: black;
$wizard-step-inner-size: 25px;
$wizard-step-inner-radius: calc($wizard-step-inner-size / 2);

$wizard-step-inner-active-color: black;

$wizard-step-outer-color: transparent;
$wizard-step-outer-size: 37px;
$wizard-step-outer-thickness: 3px;
$wizard-step-outer-radius: calc($wizard-step-outer-size / 2);

$wizard-step-outer-active-color: #616161;

$wizard-step-text-color: black;
$wizard-step-text-font-family: inherit;
$wizard-step-text-font-weight: normal;
$wizard-step-text-font-size: 16px;
$wizard-step-text-line-height: inherit;
$wizard-step-text-margin: 10px 0px 0px 0px;

$wizard-step-text-active-color: black;

$wizard-spacer-color: #dadada;
$wizard-spacer-width: 142px;
$wizard-spacer-height: 6px;
$wizard-spacer-margin: 20px 0px 0px 0px;

$wizard-spacer-width-mobile: 0px;
$wizard-spacer-height-mobile: 0px;
$wizard-spacer-margin-mobile: $wizard-spacer-margin;

$wizard-step-change-duration: 600ms;
$wizard-step-change-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

@import '../../../app/q360-comps/wizard-layout/src/wizard-layout.scss';
