$dock-background-color: rgb(255, 255, 255);

.dock {
  display: none;
  position: fixed;
  bottom: 0px;
  background: $dock-background-color;
  left: 0px;
  right: 0px;
  height: $dock-height-mobile;
  border-top: 1px solid $med-grey;
  z-index: 201;

  @media screen and (max-width: 1023px) {
    display: block;
  }

  .main-nav {
    display: block;
    width: 100%;

    .nav-list {
      display: flex;
      justify-content: space-evenly;
      padding: 0;
      margin: 1rem 0;
    }
  }
}
