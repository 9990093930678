$no-tiles-color: black !default;
$no-tiles-font-family: inherit !default;
$no-tiles-font-weight: normal !default;
$no-tiles-font-size: 30px !default;
$no-tiles-line-height: inherit !default;
$no-tiles-text-align: center !default;

.no-tiles {
  color: $no-tiles-color;
  font-family: $no-tiles-font-family;
  font-weight: $no-tiles-font-weight;
  font-size: $no-tiles-font-size;
  line-height: $no-tiles-line-height;
  text-align: $no-tiles-text-align;
}
