.content {
  &.emp-verify, &.code-verification {
    padding: 40px 123px !important;
    max-width: 800px;

    @media screen and (max-width: 1023px) {
      text-align: center;
    }

    @media  screen and (max-width: 767px) {
      padding: 40px !important;
    }

    .content-header {
      font-size: 42px;
      color: #071D49;
      padding-bottom: 65px;

      @media screen and (max-width: 1023px) {
        font-size: 32px;
      }
    }

    .content-description {
      margin-bottom: 0 !important;
    }

    .content-label {
      margin-left: 8px;
    }

    .content-body {
      background: none;
    }

    .form-group {
      &.email, &.code {
          margin: 0px;

          label{

            span{
    
              span{
                font-size: 16px;
                padding-left: 0px !important;
                color: $black !important;
    
                @media screen and (max-width: 767px) {
                  font-size: 13px;
                }
              }
            }

            input{
              margin: 20px 0px 5px 0px !important;
              max-width: 450px;
    
              @media screen and (max-width: 767px) {
                margin: auto;
              }
            }
          }
        }
      }
        .form-group.submit {
          padding-top: 0rem;
          margin: 0px;
          border-top: none;
    
          @media screen and (max-width: 767px) {
            max-width: 450px;
            margin: auto;  
          }
        }
      }
    }
