$nav-notification-color: white !default;
$nav-notification-background-color: #f32100 !default;
$nav-notification-font-family: inherit !default;
$nav-notification-font-size: 14px !default;
$nav-notification-font-weight: normal !default;

.main-nav {
  display: inline-block;

  .nav-list {
    list-style: none;
    margin: 1rem;
    padding: 0;

    > li {
      position: relative;
      display: inline-block;
      width: 84px;

      &:active > .icon,
      &:hover > .icon {
        // This variable comes from colors.scss
        color: $icon-color;
      }

      &:active > .icon svg,
      &:hover > .icon svg {
        fill: $icon-color;
      }

      .nav-list-item-link {
        margin: auto;
        width: 64px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 1024px) {
          height: 48px; // IE fix -> issue #147, also fixes the more menu icon on navigation dock
        }
      }

      a[href*='tsys-activity'] {
        color: #00A9E0;
        font-size: 12px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin: 64px 20px 20px 20px;
        padding-left: 0;
        padding-right: 0;
        background: url('../../../../assets/img/svg-icons/caret_right.svg') no-repeat right white;
        background-size: 20px;
      }
    }
  }

  .notification {
    position: absolute;
    width: 23px;
    height: 23px;
    background-color: #f32100;
    color: white;
    border-radius: 11.5px;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-family: $nav-notification-font-family;
    font-size: $nav-notification-font-size;
    font-weight: $nav-notification-font-weight;
    left: calc(50% + 8px);
    top: 17px;
    z-index: 32;
  }

  .icon {
    position: relative;
    display: block;
    z-index: 31;
    text-align: center;
    font-size: 13px;
    color: $header-font-color;
    text-decoration: none;

    &.home, // dont thing these are necessary
        &.rewards, // dont thing these are necessary
        &.more, // dont thing these are necessary
        &.challenges, // dont thing these are necessary
        &.powerup,
        &.icon-grey {
      fill: $header-font-color;
      color: $header-font-color;
    }

    .svg-icon {
      width: 24px;
      margin: auto;
    }
  }

  .subnav {
    position: absolute;
    padding: 0px;
    margin: 21px;
    top: 66px;
    right: -20px;

    &:hover li .icon {
      color: #171717;
    }

    ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
      box-shadow: 0px 3px 7px 2px $header-subnav-shadow-color;
    }

    a,
    a:link {
      display: block;
      min-width: 260px;
      padding: 17px;
      text-decoration: none;
      font-size: 17px;
      font-weight: normal;
      color: $header-subnav-font-color;
      text-align: left;
    }

    a:hover {
      color: $header-subnav-hover-font-color;
      background-color: $header-subnav-hover-background-color;
    }

    li {
      background-color: $header-subnav-background-color;
      white-space: nowrap;
      border: none;
    }

    * + * {
      border-top: 1px solid $header-subnav-divider-color;
    }
  }

  .subnav {
    .icon {
      display: none;
    }
  }
}
