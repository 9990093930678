$date-time-input-padding: 0px 10px 0px 0px !default;

$date-time-input-popup-background-color: white !default;
$date-time-input-popup-width: 250px !default;
$date-time-input-popup-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1) !default;
$date-time-input-popup-margin: 28px 0 0 auto !default;

$date-time-input-popup-background-color-mobile: rgba(0, 0, 0, 0.85) !default;
$date-time-input-popup-width-mobile: 320px !default;
$date-time-input-popup-box-shadow-mobile: none !default;
$date-time-input-popup-margin-mobile: auto auto !default;

$date-time-input-popup-footer-width: calc(100% - 10px) !default;
$date-time-input-popup-footer-border: 1px solid lightgray !default;
$date-time-input-popup-footer-padding: 5px 5px 10px 5px !default;

$date-time-input-clear-color: black !default;
$date-time-input-clear-font-family: inherit !default;
$date-time-input-clear-font-weight: normal !default;
$date-time-input-clear-font-size: 14px !default;
$date-time-input-clear-line-height: inherit !default;
$date-time-input-clear-margin: 0px !default;

$date-time-input-clear-font-size-mobile: 20px !default;
$date-time-input-clear-line-height-mobile: inherit !default;
$date-time-input-clear-margin-mobile: 5px !default;

$date-time-input-button-background-color: transparent !default;
$date-time-input-button-border: none !default;
$date-time-input-button-border-radius: 0px !default;
$date-time-input-button-padding: 6px 0px !default;
$date-time-input-button-margin: 0px !default;
$date-time-input-button-width: auto !default;
$date-time-input-button-height: auto !default;

$date-time-input-calendar-icon-width: 20px !default;
$date-time-input-calendar-icon-height: 22.38px !default;
$date-time-input-calendar-icon-padding: 0px !default;

$date-time-input-calendar-icon-fill: white !default;
$date-time-input-calendar-icon-stroke: #231f20 !default;
$date-time-input-calendar-icon-line-stroke: #231f20 !default;
$date-time-input-calendar-icon-header-fill: #231f20 !default;
$date-time-input-calendar-icon-header-line-one-stroke: #898889 !default;
$date-time-input-calendar-icon-header-line-two-stroke: #626363 !default;
$date-time-input-calendar-icon-binder-hole-fill: #c3c3c2 !default;
$date-time-input-calendar-icon-binder-line-stroke: #231f20 !default;

$date-time-input-clock-icon-width: 20px !default;
$date-time-input-clock-icon-height: 20px !default;
$date-time-input-clock-icon-padding: 1px 0px !default;

$date-time-input-clock-icon-fill: white !default;
$date-time-input-clock-icon-stroke: #231f20 !default;
$date-time-input-clock-icon-hour-stroke: #231f20 !default;
$date-time-input-clock-icon-minute-stroke: #231f20 !default;
$date-time-input-clock-icon-center-fill: #231f20 !default;

.date-time-input {
  display: flex;
  position: relative;

  & > .input-container {
    flex: 1;
    box-sizing: border-box;
    // padding: $date-time-input-padding;

    & > input {
      box-sizing: border-box;
      width: 100%;

      &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
    }

    & > .popup-container {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 1;

      & > .popup {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        background-color: $date-time-input-popup-background-color;
        width: $date-time-input-popup-width;
        margin: $date-time-input-popup-margin;
        box-shadow: $date-time-input-popup-box-shadow;

        @media screen and (max-width: 1023px) {
          width: $date-time-input-popup-width-mobile;
          margin: $date-time-input-popup-margin-mobile;
          box-shadow: $date-time-input-popup-box-shadow-mobile;
        }

        .calendar {
          width: 100%;
        }

        div.clear {
          box-sizing: border-box;
          width: $date-time-input-popup-footer-width;
          border-top: $date-time-input-popup-footer-border;
          padding: $date-time-input-popup-footer-padding;

          button.clear {
            display: inline-block;
            color: $date-time-input-clear-color;
            background: transparent;
            border: none;
            text-align: left;
            width: auto;
            height: auto;
            cursor: pointer;
            padding: 0px;
            font-family: $date-time-input-clear-font-family;
            font-weight: $date-time-input-clear-font-weight;
            font-size: $date-time-input-clear-font-size;
            line-height: $date-time-input-clear-line-height;
            margin: $date-time-input-clear-margin;

            @media screen and (max-width: 1023px) {
              font-size: $date-time-input-clear-font-size-mobile;
              line-height: $date-time-input-clear-line-height-mobile;
              margin: $date-time-input-clear-margin-mobile;
            }
          }
        }
      }
    }
  }

  & > .button-container {
    button {
      display: inline-block;
      box-sizing: border-box;
      background-color: $date-time-input-button-background-color;
      border: $date-time-input-button-border;
      border-radius: $date-time-input-button-border-radius;
      padding: $date-time-input-button-padding;
      margin: $date-time-input-button-margin;
      width: $date-time-input-button-width;
      height: $date-time-input-button-height;

      svg {
        &.calendar-icon {
          width: $date-time-input-calendar-icon-width;
          height: $date-time-input-calendar-icon-height;
          padding: $date-time-input-calendar-icon-padding;

          .background {
            fill: $date-time-input-calendar-icon-fill;
            stroke: $date-time-input-calendar-icon-stroke;
          }

          .line {
            stroke: $date-time-input-calendar-icon-line-stroke;
          }

          .header {
            fill: $date-time-input-calendar-icon-header-fill;
          }

          .header-line-one {
            stroke: $date-time-input-calendar-icon-header-line-one-stroke;
          }

          .header-line-two {
            stroke: $date-time-input-calendar-icon-header-line-two-stroke;
          }

          .binder-hole {
            fill: $date-time-input-calendar-icon-binder-hole-fill;
          }

          .binder-line {
            stroke: $date-time-input-calendar-icon-binder-line-stroke;
          }
        }

        &.clock-icon {
          display: none;
          width: $date-time-input-clock-icon-width;
          height: $date-time-input-clock-icon-height;
          padding: $date-time-input-clock-icon-padding;

          .background {
            fill: $date-time-input-clock-icon-fill;
            stroke: $date-time-input-clock-icon-stroke;
          }

          .minute {
            stroke: $date-time-input-clock-icon-minute-stroke;
          }

          .hour {
            stroke: $date-time-input-clock-icon-hour-stroke;
          }

          .center {
            fill: $date-time-input-clock-icon-center-fill;
          }
        }
      }
    }
  }

  &.time {
    & > .button-container {
      button {
        svg {
          &.calendar-icon {
            display: none;
          }

          &.clock-icon {
            display: block;
          }
        }
      }
    }
  }

  &.open {
    & > .input-container {
      & > .popup-container {
        position: absolute;
        display: block;

        @media screen and (max-width: 1023px) {
          position: fixed;
          left: 0px;
          right: 0px;
          top: 0px;
          bottom: 0px;
          background-color: $date-time-input-popup-background-color-mobile;
          z-index: 9999;
          display: flex;
        }
      }
    }
  }
}
