.linear-meter {
  position: relative;

  .level {
    .line {
      stroke: #dfd7ef;
      stroke-width: 1.2px;
    }

    .text {
      font-size: 1em;
      fill: black;
      color: black;
    }

    &:not(:first-child) {
      .line {
        width: 79px;
        margin-left: 11px;
      }
    }
  }
  .background {
    fill: #535453;
    stroke: #7c7778;
    stroke-width: 6px;
  }

  .background-mask {
    fill: #535453;
  }

  .value-gradient {
    stop:nth-child(1) {
      stop-color: blue;
    }

    stop:nth-child(2) {
      stop-color: green;
    }

    stop:nth-child(3) {
      stop-color: yellow;
    }

    stop:nth-child(4) {
      stop-color: orange;
    }

    stop:nth-child(5) {
      stop-color: red;
    }
  }
}
