$footer-header-color: #ffffff !default;
$footer-header-font-family: inherit !default;
$footer-header-font-size: 25px !default;
$footer-header-font-weight: normal !default;
$footer-header-line-height: inherit !default;
$footer-header-padding: 0px 5px 0px 0px !default;
$footer-header-margin: 0px 0px 10px 0px !default;

$footer-link-color: #bfbfbf !default;
$footer-link-font-family: inherit !default;
$footer-link-font-size: 15px !default;
$footer-link-font-weight: normal !default;
$footer-link-line-height: inherit !default;
$footer-link-padding: 0px !default;
$footer-link-margin: 0px 0px 3px 0px !default;

nav.footer-nav {
  > ul.nav-list {
    padding: 0px;
    list-style: none;

    > li.nav-list-item {
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      padding: $footer-header-padding;

      > a,
      p {
        display: inline-block;
        text-decoration: none;
        color: $footer-header-color;
        font-family: $footer-header-font-family;
        font-size: $footer-header-font-size;
        font-weight: $footer-header-font-weight;
        line-height: $footer-header-line-height;
        margin: $footer-header-margin;

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0px;
        }
      }

      > ul.nav-list {
        padding: 0px;
        list-style: none;

        > li.nav-list-item {
          display: block;
          vertical-align: top;
          padding: $footer-link-padding;

          > a {
            display: inline-block;
            font-family: $footer-link-font-family;
            font-size: $footer-link-font-size;
            font-weight: $footer-link-font-weight;
            line-height: $footer-link-line-height;
            margin: $footer-link-margin;
            text-decoration: none;
            color: #fff;

            &:hover,
            &:active {
              text-decoration: underline;
            }

            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              margin: 0px;
            }
          }
        }
      }
    }
  }
}
