.content {
    &.forgot-username-confirmation {
      max-width: 450px;
      margin: 15px auto;
  
      .content-header {
        font-size: 42px;
      }
  
      .content-description {
        margin-bottom: 30px;
      }
  
      .content-body {
        background: none;
      }
  
      .form-group {
        &.submit {
          text-align: right;
          border-top: solid 1px #aeaeae;
          padding: 15px 0px;
        }
      }
    }
  }
  